/** @format */

import {
  computedFrom
} from 'aurelia-framework';
import {
  DateTimeUtils
} from '@fonix/web-utils';

import apiService from './apiService';

export const ReportTypes = {
  1: 'journey-summary',
  2: 'current-status',
  3: 'journey-detailed',
  4: 'odometer-and-chronometer',
  5: 'day-summary',
  6: 'parked-time',
  7: 'overspeed-summary',
  8: 'overspeed-detailed',
  9: 'out-of-hours',
  10: 'ecodriving-summary',
  11: 'ecodriving-detailed',
  12: 'vehicle-summary',
  13: 'working-hours-summary',
  14: 'working-hours-detailed',
  15: 'billing-summary',
  16: 'billing-detailed',
  17: 'licencecheck-summary',
  18: 'licencecheck-detailed',
};

export class Report {
  constructor(data) {
    Object.assign(this, data);
    let id = this.settings ? this.settings.reportId : this.id;

    this.type = ReportTypes[id];
    this.name = this.reportName || this.type;
  }

  @computedFrom('settings.contextType')
  get contextType() {
    return (this.settings && this.settings.contextType) || null;
  }
}

export class ReportExecution {
  constructor(data) {
    Object.assign(this, data);
    let reportId = this.settings ? this.settings.reportId : this.reportId;
    this.type = ReportTypes[reportId];
  }

  @computedFrom('state')
  get ready() {
    return this.state === 'complete';
  }

  @computedFrom('uri')
  get link() {
    return `${this.uri}`;
  }

  @computedFrom('created')
  get dtCreated() {
    if (this.created) {
      return DateTimeUtils.toLocal(this.created);
    }
  }

  @computedFrom('completed')
  get completedCalendar() {
    return DateTimeUtils.toCalendar(this.completed || new Date(), {
      sameDay: '[Today]',
      lastDay: '[Yesterday]',
      lastWeek: 'L'
    });
  }
}

export class ReportsService {
  constructor() {
    this.api = apiService;
  }

  loadReports() {
    let sortAlpha = (a, b) =>
      (a.name.toLowerCase() > b.name.toLowerCase()) -
      (a.name.toLowerCase() > a.name.toLowerCase());
    return this.api.get('api/reports/origins').then(reports => {
      let _reports = (reports || [])
        .map(r => {
          return new Report(r);
        })
        .filter(r => !!r.type);
      return _reports.sort(sortAlpha);
    });
  }

  getExecutions(id) {
    return this.api
      .get(this.api.buildUrl('api/reports/executions', id))
      .then(executions => {
        let exec = this.api.toArray(executions).map(exec => {
          return new ReportExecution(exec);
        });
        return id ? exec[0] : exec;
      });
  }

  getReportData(key, file = false) {
    let url = this.api.buildUrl('api/reports/data', key);
    return this.api
      .get(
        url, {
        media: file ? 'csv' : 'json'
      },
        file
      )
      .then(response => {
        if (file) {
          return response.blob();
        }
        return new Report(response);
      });
  }

  getReportUrlToExport(id, media) {
    let request = window.btoa(`${media}:${id}:${this.api.token}`);
    let url = `${this.api.baseURL}api/reports/download/${request}`;
    return url;
  }

  createExecution(id, settings, force = false) {
    //todo: refactor this. no need to pass individually...
    let data = {
      reportId: id,
      contextType: settings.contextType,
      context: settings.context,
      dates: settings.dates,
      emailSettings: settings.emailSettings,
      preferences: settings.preferences,
      timeZone: settings.timeZone,
      customSettings: settings.customSettings
    };

    let forceParam = (force) ? '?force=true' : '';
    return this.api.post('api/reports/executions' + forceParam, data).then(exec => {
      return new ReportExecution(exec);
    });
  }

  //MAX 40 chars?
  renameExecution(id, name) {
    if (name && name.length) {
      let url = this.api.buildUrl('api/reports/executions', id);
      return this.api.put(url, {
        reportName: name
      }).then(() => name);
    }
    return Promise.resolve(name);
  }

  //id is execution id
  sendByEmail(id, emails) {
    let url = `api/reports/data/${id}/email`;
    return this.api.post(url, {
      addresses: emails,
      format: 'pdf'
    });
  }

  //deprecated ?
  //api/reports/data/id
  generate(id, contextType, context, dateRange, emails) {
    let data = {
      contextType,
      context: context,
      dates: dateRange,
      emailSettings: emails
    };

    let url = this.api.buildUrl('api/reports/data', id);
    return this.api.post(url, data).then(result => {
      let report = new Report({
        ...result,
        ready: true
      });
      return report;
    });
  }
}

const reportsService = new ReportsService();
export default reportsService;
