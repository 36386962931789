/** @format */

import {
  APIService
} from 'services/api/apiService';
import moment from 'moment';

export class TachographService {

  constructor() {
    this.api = new APIService('tacho');
  }


  async getFiles(dtfrom, dtto, typeSelected) {
    let urlParams = `dateFrom=${moment(dtfrom).toISOString()}&dateTo=${moment(dtto).toISOString()}&type=${typeSelected}`;
    try {
      const files = await this.api
        .get(this.api.buildUrl('files?' + urlParams));
      return files || [];
    } catch (e) {
      return 'exception';
    }
  }

  encodeMediaIdWithToken(id) {
    let request = window.btoa(`${id}:${this.api.token}`)
      .replace( /\=+$/, '' )
      .replace( '+', '-' )
      .replace( '/', '_' );

    let url = `${this.api.baseURL}files/${request}`;
    return url;
  }
}

const tachographService = new TachographService();
export default tachographService;
