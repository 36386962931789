/** @format */

import userService from 'services/api/userService';
import {
  DateTimeUtils
} from '@fonix/web-utils';

export class Conversation {
  id;
  type;
  name;
  users;
  usersName;
  messages;

  constructor(data) {
    this.id;
    this.users = [];
    this.usersName = [];
    Object.assign(this, data);
    this.messages = this.messages.map(m => new Message(m));
  }

  get totalMessages() {
    return this.messages.length;
  }

  get recentMessage() {
    if (this.messages && this.messages.length) {
      return this.messages.reduce((m, v, i) => (v.timestamp > m.timestamp) && i ? v : m);
    } else {
      return null;
    }
  }

  get updated() {
    if (this.messages && this.messages.length) {
      return this.recentMessage.timestamp;
    }
  }

  get status() {
    if (this.recentMessage) {
      if (this.recentMessage.sendTimestamp && !this.recentMessage.receivedTimestamp) {
        return 'sent';
      } else if (this.recentMessage.sendTimestamp && this.recentMessage.receivedTimestamp && !this.recentMessage.readTimestamp) {
        return 'received';
      } else if (this.recentMessage.sendTimestamp && this.recentMessage.receivedTimestamp && this.recentMessage.readTimestamp) {
        return 'read';
      }
    }
  }

  

  get badge() {
    if (this.messages && this.messages.length) {
      let messagesToRead = this.messages.filter(m => !m.readTimestamp && userService.user.id != m.userId);
      return messagesToRead.length;
    }
  }

  get initialLetter() {
    if (this.type == 'group') {
      return this.name.substring(0, 1);
    } else {
      let name = this.usersName[0];
      return name ? name.substring(0, 1) : '';
    }
  }

  get title() {
    if (this.type == 'group') {
      return this.name;
    } else {
      let name = this.usersName[0];
      return name ? name : '';
    }
  }
}

export class Message {
  id;
  message;
  userId;
  userName;
  conversationId;
  sendTimestamp;
  receivedTimestamp;
  readTimestamp;
  attachments;

  constructor(data) {
    this.attachments = [];
    Object.assign(this, data);
  }

  get timestamp() {
    return (userService.user && this.userId == userService.user.id) ? this.sendTimestamp : this.receivedTimestamp;
  }

  get date() {
    return DateTimeUtils.format(this.timestamp, 'DATE');
  }

  get status() {
    if (this.sendTimestamp && !this.receivedTimestamp) {
      return 'sent';
    } else if (this.sendTimestamp && this.receivedTimestamp && !this.readTimestamp) {
      return 'received';
    } else if (this.sendTimestamp && this.receivedTimestamp && this.readTimestamp) {
      return 'read';
    }
  }
}
