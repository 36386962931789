/** @format */

import { computedFrom } from 'aurelia-framework';
import { DateTimeUtils } from '@fonix/web-utils';
import { Telemetry } from './models/Telemetry';

import apiService from 'services/api/apiService';

//**this replicated (case-insensitive) AlertType.cs on api, since alarmType id is a enum **
//note: comparison are done case-insensitive
export const AlertTypes = {
  continousdriving: 'continousdriving',
  doorsensor: 'doorsensor',
  excessiveidle: 'excessiveidle',
  externalpower: 'externalpower',
  harshevent: 'harshevent',
  noignition: 'noignition',
  overspeed: 'overspeed',
  outofhours: 'outofhours',
  panic: 'panic',
  poienter: 'poienter',
  poileave: 'poileave',
  temperature: 'temperature',
  unidentifieddriver: 'unidentifieddriver',
  zoneenter: 'zoneenter',
  zoneleave: 'zoneleave'
};

//either like this or use a Map()
export const AlertConfigs = {}; 
AlertConfigs[AlertTypes.overspeed] = AlertTypes.overspeed;
AlertConfigs[AlertTypes.outofhours] = AlertTypes.outofhours;
AlertConfigs[AlertTypes.excessiveidle] = AlertTypes.excessiveidle;
AlertConfigs[AlertTypes.continousdriving] = AlertTypes.continousdriving;
AlertConfigs[AlertTypes.poienter] = 'poialert';
AlertConfigs[AlertTypes.poileave] = 'poialert';
AlertConfigs[AlertTypes.zoneenter] = 'zonealert';
AlertConfigs[AlertTypes.zoneleave] = 'zonealert';
AlertConfigs[AlertTypes.panic] = 'panic';
AlertConfigs[AlertTypes.externalpower] = AlertTypes.externalpower;
AlertConfigs[AlertTypes.doorsensor] = 'poialert';
AlertConfigs[AlertTypes.temperature] = 'temperature';
AlertConfigs[AlertTypes.harshevent] = AlertTypes.harshevent;

//maybe use a MAP ?
const ALARMICONS = {
  overspeed: 'fi-speedometer',
  outofhours: 'fi-timer_off',
  excessiveidle: 'fi-timer',
  noignition: 'fi-alert-triangle',
  continousdriving: 'fi-alert-triangle',
  poienter: 'fi-location_on',
  poileave: 'fi-location_off',
  generic: 'fi-alert-triangle',
  panic: 'fi-alert-circle',
  unidentifieddriver: 'fi-user-x',
  temperature: 'fi-thermometer',
  harshevent: 'fi-alert-triangle'
};

const CHANNELICONS = {
  email: 'fi-email',
  textMessage: 'fi-sms',
  sms: 'fi-sms'
};

//TODO: move this to a common utils file
const capitalize = s => (s || '').charAt(0).toUpperCase() + (s || '').slice(1);
const lowercase = s => (s || '').toLowerCase();

export class AlertConfiguration {
  constructor(data) {
    Object.assign(this, data);

    //mandatory defaults
    //make sure type is matched, api sends camelcase
    this.type = lowercase(this.type);
    this.settings = Object.assign({}, this.settings || {});
    //this is array of object, so naive deep close
    this.channels = JSON.parse(JSON.stringify(this.channels || []));

    this.contextType = this.contextType || 'assets';
    this.context = this.context || [];
  }

  @computedFrom('type')
  get icon() {
    return ALARMICONS[this.type] || ALARMICONS.generic;
  }
}

export class Alert {
  constructor(data) {
    Object.assign(this, data);

    //mandatory defaults
    this.type = lowercase(this.type);

    //We have to find a way to translate type, to do it in the view is not always possible
    //(since for tables for ex. the name prop is always the same and cannot translate actual names
    //maybe we need a valueconverter just for alerts
    this.name = this.name || capitalize(this.type);

    if (this.positions) {
      this.telemetry = this.positions.map(p => {
        return new Telemetry(p);
      });
    }

    if (this.startGpsTimestamp) {
      this.gpsStartLocal = DateTimeUtils.toLocal(this.startGpsTimestamp);
    }

    if (this.endGpsTimestamp) {
      this.gpsEndLocal = DateTimeUtils.toLocal(this.endGpsTimestamp);
    }

    if (this.gpsStartLocal && this.gpsEndLocal) {
      this.durationMS = DateTimeUtils.diff(
        this.gpsEndLocal,
        this.gpsStartLocal
      );
    }
  }
  //

  @computedFrom('type')
  get icon() {
    return ALARMICONS[this.type] || ALARMICONS.generic;
  }

  @computedFrom('channels')
  get channelIcons() {
    if (this.channels) {
      return this.channels.map(c => {
        return CHANNELICONS[c.type];
      });
    }
  }

  @computedFrom('channels')
  get channelTypes() {
    if (this.channels) {
      return this.channels.map(c => {
        return c.type;
      });
    }
  }

  @computedFrom('positions')
  get firstPosition() {
    if (this.positions) {
      return this.positions[0];
    }
  }

  @computedFrom('positions')
  get lastPosition() {
    if (this.positions && this.positions.length > 0) {
      return this.positions[this.positions.length - 1];
    }
  }

  //if this is not included, the performance it basically revaluates for ever. tanking the perf.
  @computedFrom('channels')
  get channelEmails() {
    return this.getChannelRecipients('email');
  }

  @computedFrom('channels')
  get channelSMS() {
    return this.getChannelRecipients('sms');
  }

  @computedFrom('processed')
  get processedDate() {
    if (this.processed) {
      return DateTimeUtils.format(this.processed, 'DateTime');
    }
  }

  getChannelRecipients(type = 'email') {
    if (this.channels.length) {
      return this.channels
        .filter(c => c.type === type)
        .map(c => {
          return c.recipients;
        })
        .join(',');
    }
    return null;
  }
}

export class AlertsService {
  constructor() {
    this.api = apiService;
  }

  getConfigurations() {
    let url = this.api.buildUrl('api/alerts/definitions'); //loading
    return this.api.get(url).then(defs => {
      return (defs || []).map(d => {
        return new AlertConfiguration(d);
      });
    });
  }

  getOccurrences(id, assetId, dtfrom, dtto, onlyUnprocessed) {
    let url = this.api.buildUrl('api/alerts/occurrences', id);
    let qs = this.api.getFilters(assetId, dtfrom, dtto);

    return this.api.get(url, { ...qs, onlyUnprocessed }).then(_alarms => {
      let alarms = this.api.toArray(_alarms).map(a => new Alert(a));
      return id ? alarms[0] : alarms;
    });
  }

  getAssetOccurrences(id, dtfrom, dtto) {
    return this.getOccurrences(null, id, dtfrom, dtto);
  }

  getOccurrencesSummary(dtfrom) {
    return this.api
      .get('api/alerts/occurrences/summary', {
        dtfrom: dtfrom || DateTimeUtils.subtract(new Date(), 3, 'hour')
      })
      .then(summary => {
        return {
          count: summary.count,
          alerts: summary.alerts.map(a => {
            return new Alert(a);
          })
        };
      });
  }

  updateConfiguration(alertCfg) {
    let url = this.api.buildUrl('api/alerts/definitions', alertCfg.id); //loading
    return this.api.update(url, alertCfg).then(updated => {
      return new AlertConfiguration(Object.assign({}, alertCfg, updated));
    });
  }

  deleteConfiguration(alertCfg) {
    let url = this.api.buildUrl('api/alerts/definitions', alertCfg.id);
    return this.api.delete(url).then(res => {
      return true;
    });
  }

  updateOccurrence(alert) {
    let url = this.api.buildUrl('api/alerts/occurrences', alert.id);
    return this.api
      .update(url, {
        id: alert.id,
        processed: !!alert.processed,
        comment: alert.comment
      })
      .then(_alert => {
        return new Alert(Object.assign({}, alert, _alert));
      });
  }

  processAllOccurrences() {
    // return Promise.resolve(true);
    return this.api.post('api/alerts/occurrences/confirmAll');
  }
}

const alertsService = new AlertsService();
export default alertsService;
