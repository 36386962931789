/**
 * Telemetry model is a big class and contains observable properties,
 * so keep it in a seperate file
 */
import { computedFrom } from 'aurelia-framework';
import { DateTimeUtils } from '@fonix/web-utils';

/**
 * list of TelemetryEvents
 * @property {string} ignitionOn - ignitionOn
 * @property {string} ignitionOff - ignitionOff
 * @property {string} inJourney - inJourney
 */
export const TelemetryEvents = {
  ignitionOn: 'ignitionOn',
  ignitionOff: 'ignitionOff',
  inJourney: 'inJourney',
  ecoEvent: 'ecoEvent',
  parked: 'parked',
  idling: 'idling',
  alert: 'alert'
};


export class Telemetry {
  /**
   * @param {object} data - the server object with telemetry data
   */
  constructor(data) {
    Object.assign(this, data);
  }

  /**
   * get LatLng object
   * @return {Array.<Number, Number>} latlng
   */
  @computedFrom('latitude', 'longitude')
  get latlng() {
    return [this.latitude || 0, this.longitude || 0];
  }

  /**
   * get telemetry timestamp as gps Date
   * @return {Date} gps timestamp
   */
  @computedFrom('gpsTimestamp')
  get gpsLocal() {
    if (this.gpsTimestamp) {
      return DateTimeUtils.toLocal(this.gpsTimestamp);
    }
  }

  @computedFrom('created')
  get createdLocal() {
    if (this.created) {
      return DateTimeUtils.toLocal(this.created);
    }
  }

  @computedFrom('gpsLocal')
  get gpsHour() {
    if (this.gpsLocal) {
      return DateTimeUtils.format(this.gpsLocal, 'TIME');
    }
  }

  @computedFrom('gpsLocal')
  get gpsLocalCalendar() {
    if (this.gpsLocal) {
      return DateTimeUtils.toCalendar(this.gpsLocal, { sameElse: 'L LT' });
    }
  }

  @computedFrom('speedLimit', 'speed')
  get overspeeding() {
    if (this.speedLimit) {
      return this.speed > this.speedLimit;
    }
  }

  @computedFrom('speed', 'overspeeding')
  get speedLevel() {
    if (this.speed || this.speed == 0) {
      let level = 'R';

      if (this.speed <= 100) {
        level = 'B';
      }

      if (this.speed <= 50) {
        level = 'G';
      }

      if (this.overspeeding) {
        level = 'O';
      }

      return level;
    }
  }

  // @computedFrom('digital1', 'digital2',)
  get digitalInputs() {
    let sens = [];
    for (let x = 1; x <= 6; x++) {
      sens.push({
        name: 'D' + x,
        state: this['digital' + x]
      });
    }
    return sens;
  }

  get firstEvent() {
    if (this.events) {
      return this.events[0];
    }
  }

  get firstEventId() {
    let evt = this.firstEvent;
    if (evt) {
      if (evt.alert) {
        return evt.alert.type.toLowerCase();
      }
      return evt.eventId;
    }
  }

  get alert() {
    let firstEvt = (this.events || [{}])[0];
    return firstEvt.alert;
  }

  get ecodriving() {
    return (this.events || []).find(
      x => x.eventId === TelemetryEvents.ecoEvent
    );
  }

  get parked() {
    return (this.events || []).find(x => x.eventId === TelemetryEvents.parked);
  }

  get idling() {
    return (this.events || []).find(x => x.eventId === TelemetryEvents.idling);
  }

  get isIgnition() {
    return (
      this.firstEventId === TelemetryEvents.ignitionOn ||
      this.firstEventId === TelemetryEvents.ignitionOff
    );
  }

  get headingCardinal() {
    if (this.heading) {
      let bearings = ['NE', 'E', 'SE', 'S', 'SW', 'W', 'NW', 'N'];

      let index = this.heading - 22.5;
      if (index < 0) {
        index += 360;
      }
      index = parseInt(index / 45);

      return bearings[index];
    }
  }

  @computedFrom('latitude', 'longitude')
  get latlngLink() {
    return `http://maps.google.com/maps?t=h&q=loc:${this.latitude}+${
      this.longitude
    }`;
  }
}
