/** @format */

import {
  computedFrom
} from 'aurelia-framework';
import apiService from 'services/api/apiService';

import eventService, {
  EventsList
} from 'services/eventService';

//
//TODO: split this service into multiple files, its getting too big!
//

const USER_ICON = require('images/singlecolor/profile.png');


export const RulesInvertalUnits = {
  d: 'days',
  M: 'months',
}

export class Reseller {
  constructor(data) {
    this.settings = {};
    this.countryId = 'GB';

    Object.assign(this, data);
  }
}

export class Account {
  constructor(data) {
    this.settings = {};
    Object.assign(this, data);
    if (this.settings && !this.settings.authImplementation) {
      this.settings.authImplementation = 'credentials';
    }
  }
}

export class User {
  constructor(data) {
    //defaults
    this.isReseller = false;
    Object.assign(this, data);

    this.preferences = this.preferences || {};

    //mandatory default
    if (this.preferences.mapClustering === undefined) {
      this.preferences.mapClustering = true;
    }

    //noted for refactor:
    //The preferences object is growing quite long and disorganized.
    //But we should keep it shallow on the client, so here maybe split the preferences into multiple object
    //like mapPreferences = this.prefences.map; etc, instead of having nested objected which dosent play well with bindings
  }

  get isSuperAdmin() {
    return this.accountId === 1 && this.isAdmin;
  }

  get isAdmin() {
    if (this.permissions) {
      return (
        this.permissions.filter(p => {
          return p.indexOf('modules.administration') > -1;
        }).length > 0
      );
    }
    return false;
  }

  get email() {
    return this.username;
  }

  get image() {
    return USER_ICON;
  }

  get settings() {
    return this.accountSettings;
  }

  set settings(value) {
    this.accountSettings = value;
  }

  get units() {
    return this.settings ? this.settings.units : [];
  }

  get initialLetter() {
    return this.name.substring(0, 1);
  }
}

export class Permission {
  constructor(data) {
    // Object.assign(this, data);
    //data is a string
    this.permission = data || '';
  }

  get id() {
    return this.permission;
  }

  @computedFrom('permission', 'isFeature')
  get name() {
    let name = this.isFeature ? this.lastPart : this.permission;
    return name.replace(/\./g, '_');
  }

  @computedFrom('parts', 'isFeature')
  get namePrefix() {
    if (this.lastPart && this.isFeature) {
      let name = this.lastPart;
      return name.substring(0, name.indexOf('-'));
    }
  }

  @computedFrom('permission')
  get parts() {
    return this.permission.split('.') || [];
  }

  @computedFrom('parts')
  get lastPart() {
    if (this.parts.length) {
      return this.parts[this.parts.length - 1];
    }
  }

  @computedFrom('parts')
  get permissionType() {
    return this.parts[0];
  }

  @computedFrom('permissionType')
  get isModule() {
    return this.permissionType === 'modules';
  }

  @computedFrom('permissionType')
  get isFeature() {
    return this.permissionType === 'features';
  }

  get permissionIdIndex() {
    return this.isModule ? 1 : this.isFeature ? 2 : 0;
  }

  get permissionId() {
    return this.parts[this.permissionIdIndex];
  }

  get permissionParent() {
    return this.parts[this.permissionIdIndex - 1];
  }
}

export class Role {
  constructor(data) {
    Object.assign(this, data);
  }
}

export class UserService {
  constructor() {
    this.api = apiService;
    this.user = null;
  }

  getImpersonationTokens(username) {
    if (username) {
      return this.api.put('api/authorize', {
        username: username
      });
    }
    return Promise.resolve(false);
  }

  getPreference(key) {
    if (this.user) {
      return this.user.preferences[key];
    }
    return null;
  }

  getRolesList(accountId) {
    let url = this.api.buildUrl('api/roles');
    return this.api.get(url, {
      accountId
    }).then(roles => {
      return (roles || []).map(r => {
        return new Role(r);
      });
    });
  }

  getPermissionsList(accountId) {
    if (accountId) {
      return this.api
        .get(this.api.buildUrl('api/accounts', accountId))
        .then(account => {
          return (account.permissions || []).map(p => {
            return new Permission(p);
          });
        });
    } else {
      return this.api.get('api/permissions').then(permissions => {
        return permissions.map(p => {
          return new Permission(p);
        });
      });
    }
  }

  //

  //current user specific
  getSelf(refresh) {
    if (!this.user || refresh === true) {
      return this.api.get('api/users/self').then(user => {
        this.user = new User(user);
        return new User(this.user);
      });
    } else {
      return Promise.resolve(new User(this.user));
    }
  }

  updateSelf(user) {
    if (user) {
      return this.api
        .put('api/users/self', this.normalizeUserModel(user))
        .then(_user => {
          Object.assign(this.user, _user);
          //fire event
          eventService.publish(EventsList.UserDetailsChanged, this.user);
          return this.user;
        });
    }
    return Promise.resolve(false);
  }

  //
  getUsers(id, accountId) {
    let url = `api/users${id ? `/${id}` : ''}`;
    return this.api
      .get(url, {
        accountId
      })
      .then(users => {
        let data = this.api.toArray(users).map(d => {
          return new User(d);
        });

        return id ? data[0] : data;
        // .filter(r => r.isEnabled);
      });
  }

  updateUser(user) {
    let url = this.api.buildUrl('api/users', user.id);
    return this.api.update(url, this.normalizeUserModel(user)).then(item => {
      return new User(item);
    });
  }

  delete(id) {
    let url = this.api.buildUrl('api/users', id);
    return this.api.delete(url).then(() => true);
  }

  normalizeUserModel(user) {
    let data = user;
    if (data && data.id) {
      //if user changing password post only password data
      if (data.password) {
        data = {
          id: data.id,
          password: data.password,
          oldpassword: data.oldpassword
        };
      } else {
        delete data.password;
        delete data.oldpassword;
      }
    }
    return data;
  }

  hasRole(role) {
    if (!this.user) {
      throw new Error(
        'Cannot check if User has role. User has not been loaded!'
      );
    }

    const negate = role && role.length && role[0] === '!';
    if (negate) {
      role = role.substring(1, role.length);
    }

    let hasRole = false;
    switch (role) {
      case 'reseller':
        hasRole = this.user.isReseller;
        break;
      case 'admin':
        hasRole = this.user.isSuperAdmin;
        break;
      default:
        hasRole = false;
    }

    return hasRole != negate;
  }

  hasPermission(permission) {
    if (permission) {
      if (this.user && this.user.permissions) {
        return (
          this.user.permissions.filter(p => {
            return p.indexOf(permission) > -1;
          }).length > 0
        );
      }
      return false;
    }
    return true;
  }

  hasExperimentalFeatures() {
    if (this.user && this.user.preferences && this.user.preferences.experimentalFeatures) {
      return true;
    }
    return false;
  }

  getResellers(id) {
    let url = `api/resellers${id ? `/${id}` : ''}`;
    return this.api.get(url).then(resellers => {
      return this.api.toArray(resellers).map(r => {
        return new Reseller(r);
      });
    });
  }

  getAccounts(id, resellerId, showDisabled = false) {
    let url = `api/accounts${id ? `/${id}` : ''}`;
    return this.api
      .get(url, {
        resellerId,
        showDisabled: id ? null : showDisabled
      })
      .then(accounts => {
        return this.api.toArray(accounts).map(a => {
          return new Account(a);
        });
      });
  }

  searchAccounts(name) {
    return this.api.get('api/accounts', {
      name
    });
  }

  updateReseller(reseller) {
    let url = this.api.buildUrl('api/resellers', reseller.id);
    return this.api.update(url, reseller).then(item => {
      return item ? new Reseller(item) : item;
    });
  }

  updateAccount(account) {
    let url = this.api.buildUrl('api/accounts', account.id);
    return this.api.update(url, account).then(item => {
      this.checkUserUpdated(item, account.id);
      return new Account(item);
    });
  }

  checkUserUpdated(data, id) {
    let isSelf = this.user && id === this.user.accountId;
    if (isSelf) {
      this.user.settings = data.settings;
      eventService.publish(EventsList.UserDetailsChanged, this.user);
    }
  }

  sendFeedback(rating, message) {
    return this.api.post(this.api.buildUrl('api/feedback'), {
      content: message,
      rating: rating
    });
  }

  searchUsers(search) {
    return this.api.get('api/users', {
      search
    }).then(result => {
      return result;
    });
  }

  requestDLC(properties) {
    return this.api.post('api/accounts/thirdparty', {
      applicationId: 'driver-licence-check',
      properties: properties
    });
  }

  resetAutomation() {
    return this.api.post('api/accounts/dlc/actions', {
      action: 'resetAutomation',
    });
  }

  getTheme(id) {
    return this.api.get(`api/accounts/theme`, {
      clientId: id
    }).catch(() => null);
  }
}

const userService = new UserService();
export default userService;
