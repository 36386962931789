/** @format */

import { computedFrom } from 'aurelia-framework';
import apiService from 'services/api/apiService';

export class Group {
  constructor(data) {
    Object.assign(this, data);
  }

  @computedFrom('fullName', 'name')
  get groupName() {
    return this.fullName || this.name;
  }
}

export class GroupsService {
  constructor() {
    this.api = apiService;
    this.baseUrl = 'api/groups';
  }

  get(id, accountId) {
    return this.api
      .get(this.api.buildUrl(this.baseUrl, id), {
        accountId,
        flatten: true
      })
      .then(groups => {
        let _groups = this.api.toArray(groups).map(g => {
          return new Group(g);
        });
        return id ? _groups[0] : _groups;
      });
  }

  update(group) {
    let url = this.api.buildUrl(this.baseUrl, group.id);
    return this.api.update(url, group).then(_updated => {
      return new Group(Object.assign({}, group, _updated));
    });
  }
}


const groupsService = new GroupsService();
export default groupsService;
