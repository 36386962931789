/**
 * Object for APIFilters
 * @property {string} datefrom utc date from
 * @property {string} dateto utc date to
 * @property {string} hide explicitly hide fields
 * @property {string} show explicitly show fields
 */
export const APIFilters = {
  datefrom: 'datefrom',
  dateto: 'dateto',
  hide: 'hide',
  show: 'show'
};


