/** @format */

import {
  computedFrom
} from 'aurelia-framework';
import apiService from 'services/api/apiService';
import {
  HttpClient
} from 'aurelia-fetch-client';

import eventService, {
  EventsList
} from 'services/eventService';
import {
  apiConfig
} from 'configs';

// export class Zone
export class POI {
  constructor(data) {
    Object.assign(this, data);
    this._name = 'poi';

    if (!this.id && this.center) {
      this.geometry = this.makeGeometry(this.center);
    }
  }

  @computedFrom('center')
  get latlng() {
    if (this.center) {
      let [lng, lat] = this.center;
      return [lat, lng];
    }
  }

  makeGeometry([lng, lat]) {
    if (lat && lng) {
      return {
        type: 'Feature',
        geometry: {
          //http://geojson.org/geojson-spec.html#positions - why [lng, lat]
          coordinates: [lng, lat],
          type: 'Point'
        },
        properties: {
          radius: 100
        }
      };
    }
  }
}

export class POICollection {
  constructor(data) {
    Object.assign(this, data);
  }
}

export class POIService {
  constructor() {
    this.api = apiService;

    //TODO: seperare to its own service
    this.importsApi = new HttpClient();
    this.importsApi.configure(config => {
      config
        .withBaseUrl(apiConfig.importsUrl)
        .withDefaults({
          headers: {
            Authorization: 'Token ' + this.api.token
          }
        })
        .withInterceptor({
          response(response) {
            return response.json();
          }
        });
    });

    this.url = 'api/areas/pois';
    this.collectionsUrl = 'api/areas/poiCollections';
  }

  //POI
  get(id) {
    let url = this.api.buildUrl(this.url, id);

    return this.api.get(url).then(pois => {
      let _pois = this.api.toArray(pois).map(p => {
        return new POI(p);
      });

      return id ? _pois[0] : _pois;
    });
  }

  update(poi) {
    let url = this.api.buildUrl(this.url, poi.id);
    return this.api.update(url, poi).then(_poi => {
      //fire created event
      let p = Object.assign({}, poi, _poi);
      if (!poi.id && _poi.id) {
        eventService.publish(EventsList.POICreated, p);
      }
      return new POI(p);
    });
  }

  delete(poi) {
    let url = this.api.buildUrl(this.url, poi.id);
    return this.api.delete(url, poi);
  }

  //PoiCollections
  getCollections(id) {
    let url = this.api.buildUrl(this.collectionsUrl, id);
    return this.api.get(url).then(collections => {
      let _colls = this.api
        .toArray(collections)
        .map(pc => new POICollection(pc));
      return id ? _colls[0] : _colls;
    });
  }

  updateCollection(collection) {
    let url = this.api.buildUrl(this.collectionsUrl, collection.id);
    return this.api.update(url, collection).then(_pc => new POICollection(_pc));
  }

  deleteCollection(id) {
    let url = this.api.buildUrl(this.collectionsUrl, id);
    return this.api.delete(url, id);
  }

  shareCollection(id, accountIds) {
    const url = this.api.buildUrl(this.collectionsUrl, id, 'shared');
    return this.api.update(url, accountIds);
  }

  //Importing
  analyseCSV(file) {
    const formData = new FormData();
    formData.append('file', file);

    return this.importsApi.fetch('pois/map', {
      method: 'post',
      body: formData
    });
  }

  queueImportCSV(file, {
    mappings,
    settings,
    preview
  }) {
    const formData = new FormData();
    const importSettings = {
      ...settings,
      mappings,
      mode: preview ? 'preview' : 'commit'
    };

    formData.append('file', file);
    formData.append('settings', JSON.stringify(importSettings));

    return this.importsApi.fetch('pois', {
      method: 'post',
      body: formData
    });
  }

  getImportCSV(id) {
    return this.importsApi.fetch('pois/' + id);
  }
}

export class Zones {
  constructor(data) {
    Object.assign(this, data);
    this._name = 'zones';

    if (!this.id && this.center) {
      this.geometry = this.makeGeometry(this.center);
    }
  }

  @computedFrom('center')
  get latlng() {
    if (this.center) {
      let [lng, lat] = this.center;
      return [lat, lng];
    }
  }

  makeGeometry([lng, lat]) {
    if (lat && lng) {
      return {
        type: 'Feature',
        geometry: {
          coordinates: [lng, lat],
          type: 'Polygon'
        },
        properties: {}
      };
    }
  }
}

export class ZonesService {
  constructor() {
    this.api = apiService;
  }

  get(id) {
    let url = this.api.buildUrl('api/areas/zones', id);

    return this.api.get(url).then(zones => {
      let _zones = zones

      return id ? _zones[0] : _zones;
    });
  }

  getSimplified(includeGeometry = false) {
    let url = this.api.buildUrl('api/areas/zones/simplified');
    return this.api.get(url, {includeGeometry}).then(zones => {
      return zones;
    });
  }
}


const poiService = new POIService();

export default poiService;
export const zonesService = new ZonesService();
