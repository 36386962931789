/** @format */
import apiService from 'services/api/apiService';

export class Machine {
  constructor(data) {
    Object.assign(this, data);
  }
}

export class MachinesService {
  constructor() {
    this.api = apiService;
    this.baseUrl = 'api/machines';
  }

  get(id) {
    let url = this.api.buildUrl(this.baseUrl, id);
    return this.api.get(url).then(items => {
      let machines = this.api.toArray(items).map(m => new Machine(m));
      return id ? machines[0] : machines;
    });
  }

  update(machine) {
    let url = this.api.buildUrl(this.baseUrl, machine.id);
    return this.api.update(url, machine).then(_updated => {
      return new Machine(Object.assign({}, machine, _updated));
    });
  }

  delete(machine) {
    let url = this.api.buildUrl(this.baseUrl, machine.id);
    return this.api.delete(url).then(() => true);
  }

  addKey(id) {
    let url = this.api.buildUrl(this.baseUrl, id, 'keys');
    return this.api.post(url).then(machine => new Machine(machine));
  }

  deleteKey(id, key) {
    let url = this.api.buildUrl(this.baseUrl, id, 'keys', key);
    return this.api.delete(url).then(machine => new Machine(machine));
  }
}

const machinesService = new MachinesService();
export default machinesService;
