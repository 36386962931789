import {
  APIService
} from 'services/api/apiService';
import moment from 'moment';

export class FilesService {
  constructor() {
    this.api = new APIService('forms');
  }

  async getFiles(timestamp, filterAssets, filterDrivers) {
    var urlParams = "";
    if (timestamp) {
      urlParams += `timestamp=${moment(timestamp).toISOString()}`;
    };
    if (filterDrivers) {
      filterDrivers.forEach(element => {
        urlParams != "" ? urlParams +='&' : "";
        urlParams += `tagkey=driver&tagValue=${element}`
      });
    };
    if (filterAssets && !filterDrivers) {
      filterAssets.forEach(element => {
        urlParams != "" ? urlParams +='&' : "";
        urlParams += `tagkey=asset&tagValue=${element}`;
      });
    };
    urlParams != "" ? urlParams +='&' : "";
    urlParams += `count=25`;
    try {
      const result = await this.api.get(this.api.baseURL + "files?" + urlParams, null);
      return result;
    } catch (e) {
      return undefined;
    }
  }
}

const filesService = new FilesService();
export default filesService;
