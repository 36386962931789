/** @format */

import { computedFrom } from 'aurelia-framework';
import apiService from 'services/api/apiService';

export class Address {
  constructor(data) {
    Object.assign(this, data);
    this._name = 'location';
  }

  @computedFrom('formattedAddress')
  get name() {
    return this.formattedAddress;
  }

  @computedFrom('formattedAddress')
  get fullAddress() {
    return this.formattedAddress;
  }

  @computedFrom('location')
  get latlng() {
    if (this.location) {
      return [
        this.location.latitude || this.location.lat,
        this.location.longitude || this.location.lng
      ];
    }
  }
}

export class GeoService {
  constructor() {
    this.api = apiService;
  }

  reverseGeoCoding(latitude, longitude) {
    return this.api
      .get('api/geocoding/reverse', {
        location: [latitude, longitude].join(',')
      })
      .then(addresses => {
        let addr = null;
        if (addresses && addresses.length) {
          addr = new Address(addresses[0]);
        }
        return Promise.resolve(addr);
      });
  }

  geoCoding(address) {
    return this.api
      .get('api/geocoding/search', {
        address: escape(address)
      })
      .then(_addrs => {
        return this.api.toArray(_addrs).map(a => {
          return new Address(a);
        });
      });
  }

  //api will search for poi, addresses, asses, drivers, etc
  globalSearch(query) {
    return this.api.get('api/search', { query }).then(results => {
      return results || [];
    });
  }

  //note: provider is case sensitive on the server
  searchPlace(id, provider = 'Google') {
    return this.api.get(this.api.buildUrl('api/places', id), { provider });
  }
}

const geoService = new GeoService();
export default geoService;
