/** @format */

import 'isomorphic-fetch';
import {
  HttpClient
} from 'aurelia-fetch-client';
import {
  DateTimeUtils
} from '@fonix/web-utils';
import {
  apiConfig
} from 'configs';

import {
  APIErrorParser
} from './apiErrorParser';
import {
  APIFilters
} from './apiFilters';

import eventService, {
  EventsList
} from 'services/eventService';
import authService from 'services/authService';

/**
 * The base APIService to talk to the API
 * It contains the basic helpers to manage authenticated REST resources on the server
 *
 *
 */
export class APIService extends HttpClient {
  /**
   * Initialize the fetch configuration
   * and sets up the http interceptor with the user token
   *
   */
  constructor(subDomain = null) {
    super();
    this.baseURL = (subDomain && apiConfig.apis.find(a => a.id === subDomain)) ?
      apiConfig.apis.find(a => a.id === subDomain).url : apiConfig.url
    //note: Do not set default content-type, as we need various, and we remove a default value
    //https://github.com/github/fetch/issues/505#issuecomment-293064470
    this.configure(config => {
      config
        .withBaseUrl(this.baseURL)
        .withDefaults({
          credentials: 'same-origin',
          headers: {
            Accept: 'application/json',
            'X-Requested-With': 'Fetch',
            // 'accept-enconding': 'gzip',
            // /*global __VERSION__ */
            // 'X-WebApp-Version': __VERSION__
          }
        })
        .withInterceptor({
          request(request) {
            let token = authService.getToken();
            if (token) {
              request.headers.set('Authorization', `Bearer ${token}`);
            }

            switch (request.method) {
              case 'POST':
              case 'PUT':
              case 'DELETE':
                // let ticks = ((new Date().getTime() * 10000) + 621355968000000000);
                // request.headers.set('X-Operation-Id', `${ticks.toString(16)}`);
                // break;
            }

            return request;
          }
        });
    });
  }

  /**
   * PUT method
   * @param {url} url of resources
   * @param {object} data object with payload data
   * @return {Promise}
   */
  put(url, data) {
    return this.request(url, {
      method: 'PUT',
      body: this.toBody(data)
    });
  }

  /**
   * PATCH method
   * @param {url} url of resources
   * @param {object} data object with payload data
   * @return {Promise}
   */
  patch(url, data) {
    return this.request(url, {
      method: 'PATCH',
      body: this.toBody(data)
    });
  }

  /**
   * POST method
   * @param {url} url of resources
   * @param {object} data object with payload data
   * @return {Promise}
   */
  post(url, data, raw) {
    return this.request(
      url, {
      method: 'POST',
      body: this.toBody(data)
    },
      raw
    );
  }

  // RAW data
  postReq(url, data, raw) {
    return this.request(
      url, {
      method: 'POST',
      body: data.raw,
    },
      raw
    );
  }

  /**
   * HEAD method
   * @param {url} url of resource to head
   * @return {Promise}
   */
  head(url) {
    return this.request(
      url, {
      method: 'HEAD'
    }, true);
  }

  /**
   * DELETE method
   * @param {url} url of resource to delete
   * @return {Promise}
   */
  delete(url, data, filters) {
    let filtersStr = this.buildFiltersQS(filters);
    let _url = url;
    if (filtersStr) {
      _url += `?${filtersStr}`;
    }
    return this.request(_url, {
      method: 'DELETE',
      body: this.toBody(data)
    });
  }

  /**
   * GET method
   * @param {url} url of resources
   * @param {object[]} filters from APIFilters to apply on the resource
   * @return {Promise}
   */
  get(url, filters, raw) {
    let filtersStr = this.buildFiltersQS(filters);
    let _url = url;
    if (filtersStr) {
      _url += `?${filtersStr}`;
    }
    return this.request(_url, null, raw);
  }

  update(url, model, identifier = 'id') {
    let method = this.post;
    if (model[identifier]) {
      method = this.put;
    }
    return method.call(this, url, model);
  }
  /**
   * base request helper (use GET/PUT/POST instead)
   * @param {url} url of resources§
   * @param {options} fetch request option object
   * @return {Promise}
   */
  request(url, options, raw = false) {
    return this.fetch(url, options)
      .then(response => {
        if (response.status >= 200 && response.status < 400) {
          return raw ? response : response.json().catch(() => {});
        } else {
          return Promise.reject(response);
        }
      })
      .catch(req => {
        let requestStatus = (req && !req.status) ? 0 : req.status;
        var statusCode = -1;
        if (req) {
          statusCode = (requestStatus >= 0) ? requestStatus : req.message;
        }

        let error = {
          details: req,
          message: `api_http_${statusCode}`,
          data: {
            request: {
              url,
              status: statusCode
            }
          }
        };

        if (statusCode === 426) {
          eventService.publish(EventsList.ApiUpdateRequired);
        }

        if (statusCode === 401) {
          eventService.publish(EventsList.ApiUnauthorized)
        }

        if (statusCode >= 400 && statusCode <= 599) {
          return APIErrorParser.getErrors(req).then(msg => {
            if (msg[0]) {
              error.message = msg[0];
            }
            error.messages = msg;
            return Promise.reject(error);
          });
        } else {
          return Promise.reject(error);
        }
      });
  }

  buildUrl(baseUrl, ...params) {
    let url = baseUrl;
    if (params && params.length > 0) {
      for (let p of params) {
        url += `${p ? `/${p}` : ''}`;
      }
    }

    return url;
  }
  /**
   * Build query string from filters object
   * @param {object[]} filters - The object to be serialized to query string.
   * @return {string} url querystring
   */
  buildFiltersQS(filters) {
    let strs = [];
    if (filters) {
      for (let f in filters) {
        if (filters[f]) {
          strs.push(
            `${encodeURIComponent(f)}=${encodeURIComponent(filters[f])}`
          );
        }
      }
    }
    return strs.join('&');
  }

  getFilters(id, dtfrom, dtto) {
    let _dtfrom = dtfrom || DateTimeUtils.ago24H();

    return {
      assetId: id,
      [APIFilters.datefrom]: DateTimeUtils.toAPI(_dtfrom),
      [APIFilters.dateto]: DateTimeUtils.toAPI(dtto)
    };
  }

  /**
   * Check if user token is still valid
   * @return {Promise} promise will resolve with boolean
   */
  checkToken() {
    return this.get('api/authorize')
      .then(() => {
        return Promise.resolve(true);
      })
      .catch(() => {
        return Promise.resolve(false);
      });
  }

  /**
   * return current auth service token
   * @return {string} current token
   */
  get token() {
    return authService.getToken();
  }

  /**
   * return current base api url
   * @return {string} api url
   */
  get apiBaseURL() {
    return this.baseURL;
  }

  /**
   * returns response in array
   */
  toArray(response) {
    if (!(response instanceof Array)) {
      return response ? [response] : [];
    }
    return response;
  }

  toBody(data, type = 'application/json') {
    if (data instanceof FormData) {
      return data;
    }

    const json = JSON.stringify(data !== undefined ? data : {});
    //return json //uncoment to debug/view payload on chrome dev-tools (needs content-type)
    return new Blob([json], {
      type
    });
  }
}

const apiService = new APIService();
export default apiService;
