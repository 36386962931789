/** @format */

import apiService from 'services/api/apiService';

//
export class Driver {
  constructor(data) {
    Object.assign(this, data);
  }
}

export const DriverApplications = [];
DriverApplications.push(
  {
    id: 'com.fonixtelematics.driverapp',
    name: 'driver_app' 
  }
)

/**
 * The Service responsible for DRIVER resources
 */
export class DriversService {
  constructor() {
    this.api = apiService;
    this.drivers = {};
    //
  }

  getDrivers() {
    let url = this.api.buildUrl('api/drivers');
    return this.api.get(url);
  }

  get(id, dlc) {
    let url = this.api.buildUrl('api/drivers', id);
    return this.api.get(url, { dlc }).then(_drivers => {
      let drivers = this.api.toArray(_drivers).map(d => {
        return new Driver(d);
      });
      return id ? drivers[0] : drivers;
    });
  }

  update(driver) {
    let url = this.api.buildUrl('api/drivers', driver.id);
    return this.api.update(url, driver).then(_updated => {
      return new Driver(Object.assign({}, driver, _updated));
    });
  }

  delete(id) {
    let url = this.api.buildUrl('api/drivers', id);
    return this.api.delete(url).then(() => {
      return true;
    });
  }

  requestConsent(id, dvlaDriver) {
    let url = this.api.buildUrl('api/drivers', id, 'requestConsent');
    return this.api.post(url, { ...dvlaDriver });
  }

  revokeConsent(id) {
    let url = this.api.buildUrl('api/drivers', id, 'revokeConsent');
    return this.api.post(url);
  }

  resendConsent(id) {
    let url = this.api.buildUrl('api/drivers', id, 'retryConsent');
    return this.api.post(url);
  }
  
  deleteConsent(id) {
    let url = this.api.buildUrl('api/drivers', id, 'dlc');
    return this.api.delete(url).then(() => true);
  }

  checkLicence(id) {
    let url = this.api.buildUrl('api/drivers', id, 'licence');
    return this.api.post(url).catch(e => Promise.reject(e));
  }

  searchDLC(email) {
    let url = this.api.buildUrl('api/drivers/dlc');
    return this.api.get(url, { emailaddress: email }).catch(() => null);
  }

  grantAccessApp(id, email, applicationId) {
    let url = this.api.buildUrl('api/drivers', id, 'applications');
    return this.api.post(url, {
      'applicationId': applicationId,
      'email': email
    });
  }

  revokeAccessApp(id, applicationId) {
    let url = this.api.buildUrl('api/drivers', id, 'applications');
    return this.api.delete(url, null, {'app': applicationId});
  }
}

const driversService = new DriversService();
export default driversService;
