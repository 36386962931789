/** @format */

import {
  APIService
} from 'services/api/apiService';
import moment from 'moment';
import provisionService from 'services/api/provisionService';

export class CCTVService {
  constructor() {
    this.api = new APIService('cctv');
    this.lastClick = Date.now();
    this.delay = 6000;
    this.provisionService = provisionService;
    this.deviceFeaturesChannels = {};
  }

  getStream(Uuid, Channel) {
    return this.api
      .post(this.api.buildUrl('stream'), {
        Uuid,
        Channel
      })
      .then(stream => {
        if (stream && stream.id) {
          return stream;
        }
        return null;
      })
  }

  getAllAssets() {
    return this.api
      .get(this.api.buildUrl('assets'))
      .then(assets => {
        return assets;
      }).catch(e => {
        console.log('error', e);
      });
  }

  stopLiveStreaming(id, deviceId, channel) {
    return this.api
      .post(this.api.buildUrl('stream', id, 'actions'), {
        Uuid: deviceId,
        Channel: channel,
        action: 'stopStream',
      }).catch(e => {
        console.log('error', e);
      })
  }

  buildStreamUrl(id) {
    return this.api.apiBaseURL + this.api.buildUrl('stream', id);
  }

  getRecord(assetId, channelId, dtfrom, dtto) {
    let url = this.api.buildUrl('record');
    let qs = this.api.getFilters(assetId, dtfrom, dtto);
    // return this.api.get(url, {channelId, ...qs})
    // .then(content => {
    //   return content;
    // this.buildStreamUrl('ffffffffffffffff')
    // })
    return Promise.resolve(DEMO_VIDEO_1);
  }

  getDeviceDownload(data) {
    let res = {
      response: '',
      media: {}
    }
    var raw = JSON.stringify({ "Uuid": data.device, "Channel": Number(data.channel), "MediaStart": data.startDateTime, "MediaEnd": data.endDateTime });
    return this.api
      .postReq(this.api.buildUrl('media'), {
        raw
      })
      .then(() => {
        res.response = 'success'
        return res
      }).catch(e => {
        if (e.data.request.status == 409) {
          res.response = 'conflict'
          res.media = data
          return res
        } else {
          res.response = 'error'
          return res
        }
      })
  }

  deleteDownload(mediaId) {
    const url = this.api.buildUrl('media', mediaId);
    return this.api.delete(url);
  }

  stopMedia(mediaId, camera, deviceId, type) {
    let url = this.api.buildUrl('media', mediaId, 'actions');
    return this.api.post(url, {
      Uuid: deviceId,
      Channel: camera,
      action: 'stopMedia',
    });
  }

  shareDownload(id, emails) {
    let url = this.api.buildUrl('media', id, 'actions');
    return this.api.post(url, {
      action: 'share',
      recipients: emails
    });
  }

  getMediaElement(startTime, channelNo, deviceId) {
    var raw = JSON.stringify({ "Uuid": deviceId, "Channel": channelNo, "MediaStart": startTime });
    return this.api
      .postReq(this.api.buildUrl('stream'), {
        raw
      })
      .then(media => {
        if (media.id) {
          return media;
        }
      })
  }

  getCctvData(data, dtFrom) {
    if ((this.lastClick + this.delay) <= Date.now()) {
      return this.api
        .get(this.api.buildUrl('data/' + data), null, true)
        .then(media => {
          this.lastClick = Date.now();
          
          if (media.status === 204) {
            return Promise.resolve(media.status);
          } else if (media.status === 200) {
            return Promise.resolve(media.json());
          }
        })
        .then(e => {
          if (e && e !== 204) {
            if (e.count == 0) {
              let content = { channels: 'empty' }
              return Promise.resolve(content)
            } else if (e.count && e.count > 0) {
              return this.buildObj(e, dtFrom)
            }
          } else {
            return 204;
          }
        })
        .catch(e => {
          this.lastClick = Date.now();
          console.log('Error!!', e);
          return 'error'
        })
    }
  }

  checkCookie(dtfrom, dtto) {
    let cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
      var cookiePair = cookieArr[i].split("=");
      if ('name' == cookiePair[0].trim()) {
        if (sessionStorage.getItem("mediaStart") == dtfrom && sessionStorage.getItem("mediaEnd") == dtto) {
          return decodeURIComponent(cookiePair[1]);
        }
      }
    }
  }

  getAvailableDownloads(mediaStart, mediaEnd, assetId) {
    function setQuery(data) {
      let query = ""
      for (let d in data)
        query += d + '='
          + data[d] + '&'
      return query.slice(0, -1)
    }

    mediaStart = moment(mediaStart).subtract(30, "days").toISOString();
    var params = {
      'mediaStart': mediaStart,
      'mediaEnd': mediaEnd,
      'assetId': assetId
    }

    let queryParam = setQuery(params)
    return this.api
      .get(this.api.buildUrl('media?' + String(queryParam)), null, true)
      .then(media => {
        if (media.status === 204) {
          return media.status
        } else {
          return media.json()
        }
      })
      .then(res => {
        res !== 204 ? res.reverse() : res;
        return res
      }).catch(e => {
        console.log('error', e);
      })
  }

  encodeMediaIdWithToken(id, name) {
    let request = window.btoa(`${id}:${this.api.token}`)
      .replace( /\=+$/, '' )
      .replace( '+', '-' )
      .replace( '/', '_' );

    let url = `${this.api.baseURL}${name}/${request}`;
    return url;
  }

  getStreamVideoUrl(id) {
    return `${this.api.baseURL}/streamHub?streamId=${id}`;
  }

  encodeMediaId(id, name) {
    const url = `${this.api.baseURL}${name}/${id}`;
    return Promise.resolve(url);
  }

  getMedia(id) {
    return this.api
      .get(this.api.buildUrl(`media/${id}/peek`))
      .then(media => {
        return media;
      }).catch(() => {
        return null;
      });
  }

  checkDownloadHead(id) {
    return this.api
      .head(this.api.buildUrl(`media/${id}`)).then(res => {
        return Promise.resolve(res.status);
      }).catch(e => {
        return Promise.resolve(e.details.status);
      })
  }

  getDevice(id) {
    return Promise.resolve(this.provisionService.getDevices(id).then(device => {
      this.deviceFeaturesChannels = device[0].features.find(f => f.id === 'dvr');
      return this.deviceFeaturesChannels
    }))
  }

  getAvailableMedia(dtfrom, dtto, deviceUuid) {
    var raw = JSON.stringify({ "Uuid": deviceUuid, "action": "getFileList", "arguments": { "MediaStart": dtfrom, "MediaEnd": dtto } });
    return this.api
      .postReq(this.api.buildUrl('data'), {
        raw
      }).then(media => {
        return media

      }).catch(e => {
        return null
      });
  }

  buildObj(data, dtFrom) {
    let dataChannels = data.items.map(e => e.channel)
    dataChannels = dataChannels.filter((v, i, a) => a.indexOf(v) === i);

    const content = {
      channels: [],
      items: data.items,
      chunks: data.chunks
    }

    for (let i = 0; i < dataChannels.length; i++) {
      let ch = data.items
        .find(n => n.channel == dataChannels[i]);      
      // let mapping = this.deviceFeaturesChannels.metadata?.channels
      //   ?.find(n => n.id == ch.channel && n.name && n.name != '');

      let mapping = null;

      if ( this.deviceFeaturesChannels.metadata && this.deviceFeaturesChannels.metadata.channels ) {
        mapping = this.deviceFeaturesChannels.metadata.channels.find(n => n.id == ch.channel && n.name && n.name != '');
      }

      content.channels.push({
        id: ch.channel,
        name: mapping && mapping.name && mapping.name !== '' ? mapping.name : 'Channel ' + ch.channel,
        media: [],
        chunks: [],
        camera: dataChannels[i]
      })
    }

    for (let i in data.items) {
      const item = data.items[i];
      const channel = content.channels.find( x => x.id == item.channel );

      if ( channel ) {
        const obj = {
          id: channel.media.length + 1,
          name: channel.name,
          startDateTime: moment( item.beginTime ).toISOString(),
          endDateTime: moment( item.endTime ).toISOString(),
        };

        channel.media.push( obj );
      }
    }

    // add chunks
    for ( const i in data.chunks ) {
      const chunk = data.chunks[i];
      const channel = content.channels.find( x => x.id == chunk.channel );

      if ( channel ) {
        channel.chunks.push( {
          id: channel.chunks.length + 1,
          startDateTime: moment( chunk.beginTime ).toISOString(),
          endDateTime: moment( chunk.endTime ).toISOString()
        } );
      }
    }

    return Promise.resolve(content);
  }

  deleteMediaLease(deviceUuid, channel) {
    let encodedReq = window.btoa(`${deviceUuid}+${channel}`)
      .replace( /\=+$/, '' )
      .replace( '+', '-' )
      .replace( '/', '_' );

    return this.api
      .get(this.api.buildUrl('leases/' + encodedReq))
      .then(lease => {
        return lease.metadata
      })
  }
}

const cctvService = new CCTVService();
export default cctvService;
