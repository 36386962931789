/** @format */

import { computedFrom } from 'aurelia-framework';
import apiService from 'services/api/apiService';

import { DateTimeUtils } from '@fonix/web-utils';

export const FuelTypes = {
  unknown: 'unknown',
  diesel: 'diesel',
  petrol: 'petrol',
  lpg: 'lpg'
};

export const VehicleTypes = {
  unknown: 'unknown',
  car: 'car',
  van: 'van',
  hgvTractor: 'hgvTractor',
  hgvRigid: 'hgvRigid'
};

export const AssetTypes = {
  vehicle: 'vehicle',
  mobile: 'mobile',
  trailer: 'trailer'
};

export const AssetStates = {
  travelling: 'travelling',
  unknown: 'unknown',
  idling: 'idling',
  stopped: 'stopped'
};

export const ImmobilizerStates = {
  available: 'available',
  pending: 'pending',
  active: 'active'
};

export const InactivityUnits = {
  m: 'minutes',
  h: 'hours'
};

//makes it easier to use in templates
export const ImmobilizerFlags = {
  [ImmobilizerStates.available]: 1,
  [ImmobilizerStates.pending]: 2,
  [ImmobilizerStates.active]: 3
};

export const ASSET_NAME_FORMATS = {
  default: 'default',
  vehicle: 'vehicle',
  driver: 'driver',
  vehicleAndDriver: 'vehicleAndDriver',
  driverAndVehicle: 'driverAndVehicle'
};

//this is not a class because it's nested inside Asset class already
//use a helper function
const FlattenETA = eta => {
  if (!eta || !eta.expectedRoute) return eta;

  let route = eta.expectedRoute;
  let address = route.destination.formattedAddress;
  return {
    ...eta,
    distance: route.distance,
    duration: route.duration,
    address: address,
    name: route.name || address,
    location: route.destination.location,
    polyline: route.encodedPolyline
  };
};

/**
 * The Class the represent an Asset resource
 */
export class Asset {
  /**
   * @param {object} data - the raw json data from the server
   */

  constructor(data) {
    //metadata
    this._name = 'asset';

    //defaut
    this.assetType = 'vehicle';
    Object.assign(this, data);

    //initialize asset type details object if not there yet.
    if (!this[this.assetType]) {
      this[this.assetType] = {};
    }
  }

  @computedFrom('snapshot')
  get location() {
    if (this.snapshot) {
      return this.snapshot.location;
    }
  }

  //for manual updates
  set location(value) {
    if (this.snapshot) {
      this.snapshot.location = value;
    }
  }

  @computedFrom('location')
  get latlng() {
    if (this.location) {
      return [this.location.latitude, this.location.longitude];
    }
  }

  @computedFrom('location')
  get address() {
    if (this.location && this.location.address) {
      return this.location.address.formattedAddress;
    }
  }

  @computedFrom('location')
  get shortAddress() {
    if (this.location && this.location.shortAddress) {
      return this.location.shortAddress;
    }
  }

  @computedFrom('snapshot')
  get speed() {
    if (this.snapshot) {
      return this.snapshot.speed;
    }
  }

  @computedFrom('snapshot')
  get currentOdometer() {
    if (this.snapshot) {
      return this.snapshot.odometer;
    }
  }

  set currentOdometer(value) {
    if (value !== this.currentOdometer) {
      this.snapshot = this.snapshot || {};
      this.snapshot.odometer = value;
      //PUT field
      this.odometer = value;
    }
  }

  @computedFrom('snapshot')
  get currentChronometer() {
    if (this.snapshot) {
      return this.snapshot.chronometer;
    }
  }

  set currentChronometer(value) {
    if (value !== this.currentChronometer) {
      this.snapshot = this.snapshot || {};
      this.snapshot.chronometer = value;
      //PUT field
      this.chronometer = value;
    }
  }

  @computedFrom('location')
  get heading() {
    if (this.location) {
      return this.location.heading;
    }
  }

  @computedFrom('location')
  get gpsLocal() {
    if (this.location) {
      return DateTimeUtils.toLocal(this.location.gpsTimestamp);
    }
  }

  @computedFrom('gpsLocal')
  get lastCommCalendar() {
    if (this.gpsLocal) {
      return DateTimeUtils.toCalendar(this.gpsLocal);
    }
  }

  @computedFrom('location')
  get POI() {
    if (this.location && this.location.poi) {
      return this.location.poi.name;
    }
  }

  //stopped
  //idling
  //travelling
  @computedFrom('state')
  get status() {
    let state = (this.state || '').toLowerCase();
    state = AssetStates[state];
    return state === AssetStates.unknown ? AssetStates.stopped : state;
  }

  //updates the asset with new snapshotUpdate object
  updateState(update) {
    if (update) {
      this.snapshot = update.snapshot;
      this.state = update.state;
      this.stateDuration = update.stateDuration;
      this.computedState = update.computedState;
    }
  }

  //TODO handle ETA complex object some other way
  @computedFrom('snapshot')
  get eta() {
    return this.snapshot ? FlattenETA(this.snapshot.eta) : null;
  }

  set eta(value) {
    this.snapshot.eta = this.snapshot && value ? FlattenETA(value) : null;
  }

  @computedFrom('snapshot')
  get panic() {
    // return this.snapshot && this.snapshot.panic;
    let alerts = this.snapshot && this.snapshot.activeAlerts;
    if (alerts) {
      return alerts.find(x => x.type === 'panic');
    }
  }

  @computedFrom('snapshot')
  get driverTask() {
    let t = this.snapshot && this.snapshot.driverTask;
    return t && t.finish ? t : null;
  }

  /**
   * snapshot contains the active driver (by driver id card/etc)
   * which differs from asset.driverName which is the registered driver
   */
  @computedFrom('snapshot.driverName')
  get snapshotDriverName() {
    return (this.snapshot && this.snapshot.driverName) || undefined;
  }

  /**
   * ImmobilizerFlag
   * 0 - not available
   * 1 - available
   * 2 - pending
   * 3 - active
   */
  @computedFrom('deviceFeatures', 'computedState.immobilizer')
  get immobilizerFlag() {
    if (!this.deviceFeatures || !this.computedState) return 0;

    if (!this.computedState.immobilizer) return 0;

    return ImmobilizerFlags[this.computedState.immobilizer];
  }
}

/**
 * The Service responsible for ASSET resources
 */
export class AssetsService {
  constructor() {
    this.api = apiService;

    /** @type {object}
     * @property {string} id - the id of the object
     * @property {Asset} asset - the asset object
     */
    this.assets = {};
    this.lastUpdate = null;
  }

  /**
   * Get assets
   * @param {id} - optional, device id
   * @return {Promise} resolves to list of Asset class
   */
  get(id, filterObj) {
    let url = this.api.buildUrl('api/assets', id);
    return this.api.get(url, filterObj).then(assets => {
      return this._parseAssets(assets);
    });
  }

  /**
   * Get asset by id
   * @param {string|int} id - id of the asset
   * @return {Promise} resolves to the Asset requested
   */
  getById(id) {
    return this.get(id).then(() => {
      return this.assets[id];
    });
  }

  /**
   * Get all Assets
   * @param {Bool} unassigned - return assets with no device id
   * @return {Promise} resolves to the list of {Asset}
   *
   */
  getAll(unassigned) {
    this.pGetAll = this.get(null, { all: unassigned });
    return this.pGetAll.then(assets => {
      delete this.pGetAll;

      assets.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      return Promise.resolve(assets);
    });
    //   let ass = Object.keys(this.assets).map(i => this.assets[i]);
  }

  /**
   * Get filtered Assets
   * @param {string} name - name of the searched value
   * @return {Promise} resolves to the list of {Asset}
   *
   */
  search(name) {
    return this.api.get('assets', { name });
  }

  update(asset) {
    let url = this.api.buildUrl('api/assets', asset.id);

    //cleanup object to avoid posting trash
    for (let tp in AssetTypes) {
      if (tp !== asset.assetType) {
        delete asset[tp];
      }
    }
    //remove odometer and chronometer
    return this.api.update(url, asset).then(_asset => new Asset(_asset));
  }

  /**
   * Creates a link to another asset
   * @param {object} linkTo - properties for the request body
   */
  addLink(linkTo, assetId) {
    let url = this.api.buildUrl('api/assets', assetId, 'actions');
    return this.api
      .post(url, {
        action: 'link',
        link: {
          id: linkTo.id,
          type: 'permanent',
          sources: linkTo.sources
        }
      })
  }

  /**
   * Deletes the link to another asset
   * @param {object} linkTo - url properties
   */
  deleteLink(assetId) {
    let url = this.api.buildUrl('api/assets', assetId, 'actions');
    return this.api
      .post(url, {
        action: 'unlink'
      })
  }

  findNearest(latitude, longitude) {
    return this.api
      .get('api/assets/nearest', {
        location: [latitude, longitude].join(',')
      })
      .then(assets => {
        return this._parseAssets(assets);
      });
  }

  updateETA(id, latitude, longitude, name, preview = false) {
    if (!id || !latitude || !longitude) return Promise.resolve(true);

    return this.api
      .post(`api/assets/${id}/eta`, {
        location: { latitude, longitude },
        previewOnly: preview,
        name
      })
      .then(FlattenETA);
  }

  deleteETA(id) {
    if (!id) return Promise.resolve(true);

    return this.api.delete(`api/assets/${id}/eta`);
  }

  /**
   * Toggle asset immobilizer
   * @param {int} id
   * @param {bool} enable
   * @return { ImmobilizerState } Return new computed state of immobilizer or false
   * @throws { Response } throws request exeption with message
   */
  immobilizer(id, enable) {
    //202, 200 + asset or 4xx
    return this.api
      .post(`api/assets/${id}/immobilizer`, {
        action: enable ? 'enable' : 'disable'
      })
      .then(asset => {
        if (!enable) return ImmobilizerStates.available;
        //asset.computedState.immobilizer should always be "ImmobilizerStates.active"
        return asset ? ImmobilizerStates.active : ImmobilizerStates.pending;
      });
  }

  /**
   * @private
   * parsed raw asset object from server into {Asset} instances
   * todo:stores them on a local id based dictionary for later retrieval
   * update only when delta expires (lastupdate)
   */
  _parseAssets(items = []) {
    if (items) {
      if (!(items instanceof Array)) {
        items = [items];
      }
      let assets = items.map(i => {
        return new Asset(i);
      });

      //cache assets locally in id dict
      assets.forEach(a => {
        this.assets[a.id] = a;
      });

      this.lastUpdate = new Date();
      return assets;
    }
  }
}

const assetsService = new AssetsService();
export default assetsService;
