import { APIService } from 'services/api/apiService';
import userService from 'services/api/userService';
import { Conversation, Message } from 'services/api/models/Messaging';

export class MessagingService {
  constructor() {
    this.api = new APIService('messaging');
    this.formsApi = new APIService('forms');
    this.userService = userService;
    this.conversations = [];
  }

  async getConversations(drivers, self) {
    try {
      const conversations = await this.api
        .get(this.api.buildUrl('conversations'));
      if (conversations && conversations.length) {
        conversations.forEach(conversation => {
          conversation.participants.forEach(p => {
            if (p.type == 'user') {
              conversation['type'] = p.type;
              conversation['users'] = [p.id];
              let existingUsers = drivers.filter(u => p.id == u.userId);
              existingUsers && existingUsers.length && existingUsers.forEach(u_1 => {
                conversation['usersName'] = u_1.name;
                conversation['initialLetter'] = u_1.name.charAt(0);
              });
            }
          });

          if (conversation.lastMessage) {
            if (conversation.lastMessage.sender.id == self.id) {
              conversation['status'] = 'read';
            } else {
              conversation['status'] = 'received';
              conversation['badge'] = conversation.lastMessage.data && conversation.lastMessage.data.length;
            }

            if (conversation.lastMessage.data) {
              conversation.lastMessage.data.forEach((message) => {
                if (!message.contentType) {
                  conversation.lastMessage['message'] = message.content;
                }
                if (message.contentType === 'application/json') {
                  conversation.lastMessage['attachments'] = message.id;
                }
              });
            };
          }
        });
      }

      this.conversations = conversations;
      return await Promise.resolve(this.conversations);
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async downloadById(attachment, timezone) {
    var timezone = timezone != null ? Intl.DateTimeFormat().resolvedOptions().timeZone : timezone;
    if (attachment.contentType == "application/json+form") {

      const url = this.formsApi.apiBaseURL + "files/" + attachment.id + "/pdf";
      const response = await this.formsApi.get(url, { 'tz': timezone }, true);
      var filename = "";
      for (var pair of response.headers.entries()) {
        if(pair[0] === "content-disposition") {
          filename = pair[1].split('filename=')[1].split(';')[0].toString().replaceAll("\"","");
          break;
        }
      }
      const blob = await response.blob();
      const url2 = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url2;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url2);

    } else {
      this.api
        .post(this.api.buildUrl(`attachments/downloads`), { attachmentId: attachment.id })
        .then(res => {
          let url = this.api.buildUrl(`attachments/downloads`, res.downloadId);
          window.open(this.api.apiBaseURL + "/" + url);
        });
    }
  }

  async addConversation(driver) {
    let payload = {
      participants: [
        {
          type: 'user',
          id: driver.userId.toString()
        }
      ]
    };
    try {
      const res = await this.api.post(this.api.buildUrl(`conversations/`), payload);
      let newConversation = {
        id: res.id,
        key: res.key,
        messages: [],
        selected: true,
        type: 'user',
        users: [driver.userId.toString()],
        messages: [],
        usersName: driver.name,
        initialLetter: driver.name.charAt(0)
      };
      return await Promise.resolve(newConversation);
    } catch {
      return;
    }
  }

  async getMessages(id, timestamp) {
    timestamp = timestamp ? `timestamp=${timestamp.sent}` : '';
    let url = `conversations/${id}/messages?${timestamp}&count=11`;

    try {
      const messages = await this.api
        .get(this.api.buildUrl(url));
      return await Promise.resolve(messages || []);
    } catch (e) {
      console.log('error', e);
    }
  }

  updateConversation(data) {
    let conversation = this.conversations.find(conversation => conversation.id == data.id);
    if (conversation) {
      conversation.type = data.type;
      conversation.name = data.name;
      conversation.users = data.users;
      conversation.usersName = data.usersName;
    } else {
      this.conversations.push(new Conversation(data));
    }
    return this.conversations;
  }

  receiveMessage(message) {
    if (message && message.conversationId) {
      let conversation = this.conversations.find(conversation => conversation.id == message.conversationId);
      if (conversation) {
        conversation.messages.push(new Message(message))
      }
    }
  }

  async sendMessage(message, conversationId) {
    if (message) {
      let payload = {
        data: message.data
      }
      try {
        const response = await this.api.post(this.api.buildUrl(`conversations/${conversationId}/messages`), payload);

        return await Promise.resolve(response.id);
      } catch {
        return;
      }
    }
  }

  sendFileAsync(
    conversationId, payload) {
    var response = this.api.post(
      this.api.buildUrl(`conversations/${conversationId}/messages`),
      {
        'data': payload,
        'metadata': "",
      },
    );
    return Message.fromJson(response);
  }
}

const messagingService = new MessagingService();
export default messagingService;
