/** @format */
import {
  APIService
} from 'services/api/apiService';
import {
  DateTimeUtils as DTU
} from '@fonix/web-utils';

export class DashboardService {
  constructor() {
    this.api = new APIService('dashboard');
  }

  get(id, period, tz = DTU.detectTZ()) {
    let url = this.api.buildUrl('kpis', id);
    return this.api.get(url, {
      period,
      tz
    }).then(items => {
      let kpis = this.api.toArray(items);
      return id ? kpis[0] : kpis;
    });
  }
}

const dashboardService = new DashboardService();
export default dashboardService;
