/** @format */

import { HttpClient } from 'aurelia-fetch-client';
import { analyticsConfig } from 'configs';
import LOG from './loggerServices';
import eventService, { EventsList } from './eventService';

import storageService from './storageService';

/**
 * https://docs.bugsnag.com/platforms/browsers/configuration-options/
 */
import bugsnag from 'bugsnag-js';

/**
 * Service responsible for app analytics
 */
export class AnalyticsService {
  constructor() {
    /** @type {HttpClient} http - new aurelia fetch client instance */
    this.http = new HttpClient();

    this.bugsnag = bugsnag(analyticsConfig.bugsnag);

    // if (window.heap) {
    //   window.heap.load(analyticsConfig.heap);
    // }

    this.initEvents();
  }

  // get heap() {
  //   return window.heap;
  // }

  initEvents() {
    if (!analyticsConfig.bugsnag.autoNotify) {
      window.addEventListener('error', this.onUnhandledException.bind(this));

      //listen to bluebirds (aurelias promise polyfill implementation)
      //http://bluebirdjs.com/docs/api/error-management-configuration.html
      window.addEventListener(
        'unhandledrejection',
        this.onBluebirdPromiseException.bind(this)
      );
    }

    eventService.subscribe(EventsList.AppRouterChanged, () =>
      this.onPageNavigation.bind(this)
    );
  }

  /**
   * runs on unhandledrejection
   */
  onBluebirdPromiseException(event) {
    let detail = event.detail || { reason: JSON.stringify(event) };
    let message = detail.reason.message || detail.reason;
    this.onUnhandledPromiseException(new Error(message));
  }

  /**
   * runs on onunhandledrejection
   */
  onUnhandledPromiseException(error) {
    this.trackException(
      error,
      'onUnhandledPromiseException',
      'warning',
      (error || {}).data
    );
  }

  /**
   * runs window.onerror
   */
  onUnhandledException(errorEvent) {
    let error = errorEvent.error || errorEvent.message || errorEvent;
    this.trackException(error, 'onUnhandledException');
  }

  /**
   * runs on page change
   */
  onPageNavigation(e) {
    //bugsnag limits x error per page, reset this limit on page change
    if (this.bugsnag) {
      this.bugsnag.refresh();
    }
    let fragment = e.instruction.fragment;
    this.trackPage(fragment, window.location.href);
  }

  /**
   * track exception
   * @param {Error} error - error object thrown in exceptions
   * @param {string} name - error name
   * @param {string} severity - info/warning/error
   * @param {object} data - data with extra information
   */
  trackException(error, name, severity = 'warning', data) {
    if (!error) return;

    if (this.bugsnag) {
      this.bugsnag.notify(error, { name, metaData: data, severity });
    } else {
      LOG.error(error, name, data);
    }
  }

  /**
   * track custom error
   * @param {Error} name - error name
   * @param {string} message - error message
   * @param {object} data - data with extra information
   * @param {string} severity - info/warning/error
   */
  trackError(name, message, data, severity = 'info') {
    if (!name) return;

    if (this.bugsnag) {
      //if data object dosen't share root, messages are not grouped
      this.bugsnag.notify({ name, message, metaData: data, severity });
    } else {
      LOG.warn(name, message, data);
    }
  }

  /**
   * track page
   * @param {string} fragment - url fragment of router
   * @param {string} url - current window url
   */
  trackPage(fragment, url) {
    if (this.insights) {
      this.insights.trackPageView(fragment, url);
    } else {
      LOG.info(fragment, url);
    }
  }

  /**
   * track event
   * @param {string} name - name of the event
   * @param {object} data - Map of string to string - keep short
   * https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md
   */
  trackEvent(name, data) {
    if (this.insights) {
      this.insights.trackEvent(name, data);
    } else {
      LOG.info(name, data);
    }
  }

  /**
   * set auth user
   * @param {string} userToken - the user api token
   */
  setUser(user) {
    if (this.bugsnag) {
      let { name, email } = user;
      this.bugsnag.user = { name, email };
    }

    //temp - heap should work without being ready, but it seems in some cases
    //its throwing an exception on identify when not ready
    // const setHeap = () => {
    //   if (this.heap) {
    //     let impersonating = !!storageService.get('fonix-impersonate');

    //     let id = `${user.email}${impersonating ? '[impersonated]' : ''}`;

    //     try {
    //       let userProps = {
    //         Name: user.name,
    //         Account: user.accountName,
    //         IsReseller: user.isReseller
    //       };

    //       this.heap.identify(id);
    //       this.heap.addUserProperties(userProps);
    //     } catch (e) {
    //       LOG.warn('setting heap error', this.heap);
    //       this.trackException(e, 'Heap error', 'warning', id);
    //       //try again
    //       setTimeout(setHeap.bind(this), 500);
    //     }
    //   }
    // };

    // setHeap();
  }

  setToken(token) {
    if (this.insights) {
      this.insights.setAuthenticatedUserContext(token);
    }
  }
}

const analyticsService = new AnalyticsService();

export default analyticsService;
