/** @format */

import {
  computedFrom
} from 'aurelia-framework';
import {
  apiConfig
} from 'configs'
import {
  DateTimeUtils as DTU
} from '@fonix/web-utils';

import apiService from 'services/api/apiService';

//**this replicated (case-insensitive) ReminderType.cs on api, since alarmType id is a enum **
//note: comparison are done case-insensitive
export const ReminderTypes = {
  service: 'service',
  renewal: 'renewal'
};

//either like this or use a Map()
export const ReminderConfigs = {};
ReminderConfigs[ReminderTypes.service] = ReminderTypes.service;
ReminderConfigs[ReminderTypes.renewal] = ReminderTypes.renewal;

export const ReminderStatus = {
  planned: {
    id: 'planned',
    value: 'planned',
    priority: 1
  },
  duesoon: {
    id: 'dueSoon',
    value: 'duesoon',
    priority: 3
  },
  overdue: {
    id: 'overdue',
    value: 'overdue',
    priority: 4
  },
  resolved: {
    id: 'resolved',
    value: 'resolved',
    priority: 0
  }
}

export const TimeIntervalUnits = {
  w: 'weeks',
  m: 'months',
  y: 'years',
}

//maybe use a MAP ?
const ReminderSetIcons = {
  service: 'fi-service',
  renewal: 'fi-refresh'
};

const ReminderContextIcons = {
  assets: 'fi-car',
};

const ChannelIcons = {
  email: 'fi-email',
};

//TODO: move this to a common utils file
const capitalize = s => (s || '').charAt(0).toUpperCase() + (s || '').slice(1);
const lowercase = s => (s || '').toLowerCase();

export class ReminderConfiguration {
  constructor(data) {
    Object.assign(this, data);

    this.contextType = this.contextType || 'assets';
    this.context = this.context || [];

    if (this.status) {
      this.statusPriority = ReminderStatus[this.status.toLowerCase()] ? ReminderStatus[this.status.toLowerCase()].priority : 0;
    }
    if (this.status != ReminderStatus.resolved.id) {
      if (this.settings.overdueDate) {
        this.settings.overdueDateStatus = this.getDateStatus(this.settings.overdueDate, this.settings.duesoonDate)
      }
      if (this.settings.overdueMileage) {
        this.settings.overdueMileageStatus = this.getMeasureStatus(this.settings.overdueMileage, this.metadata.odometer, this.settings.duesoonMileage)
      }
      if (this.settings.overdueUsage) {
        this.settings.overdueUsageStatus = this.getMeasureStatus(this.settings.overdueUsage, this.metadata.chronometer, this.settings.duesoonUsage)
      }
    }
  }

  @computedFrom('contextType')
  get icon() {
    return ReminderContextIcons[this.contextType] || ReminderContextIcons.assets;
  }


  getDateStatus(overdueDate, duesoonDate) {
    let status = '';

    if (DTU.isAfter(new Date(), new Date(overdueDate))) {
      status = ReminderStatus.overdue.id;
    } else if (duesoonDate && DTU.isAfter(new Date(), new Date(duesoonDate))) {
      status = ReminderStatus.duesoon.id;
    }

    return status;
  }

  getMeasureStatus(overdueMeasure, currentMeasure, duesoonMeasure) {
    let status = '';
    if (currentMeasure >= overdueMeasure) {
      status = ReminderStatus.overdue.id;
    } else if (duesoonMeasure && currentMeasure >= duesoonMeasure) {
      status = ReminderStatus.duesoon.id;
    }

    return status;
  }
}

export class ReminderSetConfiguration {
  constructor(data) {
    Object.assign(this, data);

    //mandatory defaults
    //make sure type is matched, api sends camelcase
    this.type = lowercase(this.type);
    this.settings = Object.assign({}, this.settings || {});
    this.owner = this.owner || {
      type: null
    }
    //this is array of object, so naive deep close
    this.settings.channels = JSON.parse(JSON.stringify(this.settings.channels || []));
    if (this.status) {
      this.statusPriority = ReminderStatus[this.status.toLowerCase()] ? ReminderStatus[this.status.toLowerCase()].priority : 0;
    }

    this.customProperties = {};
    if (this.settings.timeInterval) {
      this.customProperties.timeInterval = this.getSplittedString(this.settings.timeInterval);
    }
    if (this.settings.timeThreshold) {
      this.customProperties.timeThreshold = this.getSplittedString(this.settings.timeThreshold);
    }
    if (this.settings.renewalThreshold) {
      this.customProperties.renewalThreshold = this.getSplittedString(this.settings.renewalThreshold);
    }
  }

  @computedFrom('type')
  get icon() {
    return ReminderSetIcons[this.type] || ReminderSetIcons.service;
  }

  getSplittedString(timeInterval) {
    if (timeInterval) {
      var value = parseInt(timeInterval.match(/\d/g).join(""));
      value = (value) ? value : '0';
      var unit = timeInterval.replace(/\d+/g, '');
      unit = (unit && Object.keys(TimeIntervalUnits).find(unitKey => unitKey == unit)) ? TimeIntervalUnits[unit] : Object.keys(TimeIntervalUnits)[0];
      return {
        value,
        unit
      }
    }
  }
}

export class RemindersService {
  constructor() {
    this.api = apiService;
  }

  getRemindersSets(filters) {
    let url = this.api.buildUrl('api/reminders/sets');
    return this.api.get(url, filters).then(sets => {
      return (sets || []).map(set => {
        return new ReminderSetConfiguration(set);
      });
    });
  }

  getReminderSet(id) {
    let url = this.api.buildUrl('api/reminders/sets', id);
    return this.api.get(url).then(reminderSet => {
      return new ReminderSetConfiguration(reminderSet);
    });
  }

  getReminders(filters) {
    let url = this.api.buildUrl('api/reminders');
    return this.api.get(url, filters).then(sets => {
      return (sets || []).map(set => {
        return new ReminderConfiguration(set);
      });
    });
  }

  getReminder(id) {
    let url = this.api.buildUrl('api/reminders', id);
    return this.api.get(url).then(reminder => {
      return new ReminderConfiguration(reminder);
    });
  }

  createReminderSet(reminderSet) {
    let url = this.api.buildUrl('api/reminders'); //loading
    return this.api.post(url, reminderSet).then(updated => {
      return new ReminderSetConfiguration(Object.assign({}, reminderSet, updated));
    });
  }

  updateReminderSet(reminderSet) {
    let url = this.api.buildUrl('api/reminders/sets', reminderSet.id); //loading
    return this.api.update(url, reminderSet).then(updated => {
      return new ReminderSetConfiguration(Object.assign({}, reminderSet, updated));
    });
  }

  createReminderComment(id, comment) {
    let url = this.api.buildUrl('api/reminders', id, 'comments'); //loading
    return this.api.post(url, comment).then(updated => {
      return updated;
    });
  }

  updateReminderStatus(id, status) {
    let url = this.api.buildUrl('api/reminders', id); //loading
    return this.api.patch(url, status).then(updated => {
      return updated;
    });
  }

  updateMultipleReminderStatus(reminders, status) {
    return new Promise(resolve => {
      reminders.forEach((id, index) => {
        this.updateReminderStatus(id, status).then(res => {
          if (index + 1 == reminders.length) {
            resolve(true);
          }
        })
      });
    })
  }

  updateMultipleReminders(details) {
    let url = this.api.buildUrl('api/reminders'); //loading
    return this.api.update(url, details, 'ids').then(updated => {
      return updated
    });
  }

  deleteReminderSet(reminderSet) {
    let url = this.api.buildUrl('api/reminders/sets', reminderSet.id);
    return this.api.delete(url).then(res => {
      return true;
    });
  }

  deleteReminders(ids, setId) {
    let url = this.api.buildUrl('api/reminders');
    let data = {
      ids,
      setId
    };
    return this.api.delete(url, data).then(res => {
      return true;
    });
  }
}

const remindersService = new RemindersService();
export default remindersService;
