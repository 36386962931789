export class APIErrorParser {
  static getErrors(request) {
    if (request.json) {
      return request.json().then(body => {
        if (!body.modelState) {
          return Promise.resolve([body.message]);
        }

        let errs = [];
        for (let k in body.modelState) errs.push(...body.modelState[k]);

        return Promise.resolve(errs);
      })
      .catch(() => {
        return Promise.resolve([request.statusText]);
      });
    }
    else {
      return Promise.resolve([request.statusText || request.message]);
    }
  }
}
