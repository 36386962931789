/** @format */
import apiService from 'services/api/apiService';
import {
  DateTimeUtils as DTU
} from '@fonix/web-utils';

export class Demo {
  constructor(data) {
    this.accountId;
    this.type = 'demo';
    this.name = '';
    this.expires = DTU.add(DTU.startOf(DTU.today()), 30, 'days');

    Object.assign(this, data);
  }
}

export class GuestService {
  constructor() {
    this.api = apiService;
  }

  get(id, filters = {}) {
    let url = this.api.buildUrl('api/guests', id);
    return this.api.get(url, filters).then(guests => {
      let data = this.api.toArray(guests).map(d => {
        return new Demo(d);
      });

      return id ? data[0] : data;
    })
  }

  update(guest) {
    let url = this.api.buildUrl('api/guests', guest.id);
    return this.api
      .update(url, guest)
      .then(() => {
        return Promise.resolve();
      })
      .catch(() => {
        return Promise.reject();
      });
  }

  delete(guest) {
    let url = this.api.buildUrl('api/guests', guest.id);
    return this.api.delete(url, guest).then(() => {
        return Promise.resolve();
      })
      .catch((e) => {
        return Promise.reject();
      });
  }

  actions(guest, data) {
    let url = this.api.buildUrl('api/guests', guest.id, 'actions');
    return this.api.post(url, data).then((r) => {
        return Promise.resolve(r);
      })
      .catch((e) => {
        return Promise.reject(e);
      });
  }
}

const guestService = new GuestService();
export default guestService;
