/** @format */

// import { computedFrom } from 'aurelia-framework';
import { DateTimeUtils } from '@fonix/web-utils';
import googleService from 'services/googleService';

import apiService from 'services/api/apiService';
import { Telemetry } from './models/Telemetry';

export const JourneyLabels = {
  business: 'business',
  personal: 'personal',
};

/**
 * NOTE:
 * Journey and Trip are the exact SAME thing
 * todo: replace all mentions of trip to journey in the codebase
 */

export class Journey {
  constructor(data) {
    Object.assign(this, data);

    if (this.startGpsTimestamp) {
      this.gpsStartLocal = DateTimeUtils.toLocal(this.startGpsTimestamp);

      if (this.endGpsTimestamp) {
        this.gpsEndLocal = DateTimeUtils.toLocal(this.endGpsTimestamp);
      }

      let end = this.gpsEndLocal || new Date();
      this.durationMS = DateTimeUtils.diff(end, this.gpsStartLocal);

      this.gpsDate = DateTimeUtils.format(this.gpsStartLocal, 'DAYMONTH');
      this.startYMD = DateTimeUtils.format(this.gpsStartLocal, 'YMD');
      this.startHHMM = DateTimeUtils.format(this.gpsStartLocal, 'TIME');
    }

    this.isPersonal = this.label === JourneyLabels.personal;
  }
}

export class TelemetryService {
  constructor() {
    this.api = apiService;

    /** @type {object}
     * @property {string} id - the id of the object
     * @property {Telemetry} asset - the asset object
     */
    this.telemetryData = {};
  }

  /**
   * Returns a device telemetry data by id
   *
   * @returns {Promise<Telemetry[]>} list of telemetry/positions of the asset
   */
  getPositions(
    id,
    dtfrom,
    dtto,
    roadSpeeds = true,
    interpolate = false,
    noFilter = false
  ) {
    let qs = this.api.getFilters(id, dtfrom, dtto);
    return this.api
      .get('api/telemetry/positions', { ...qs, noFilter })
      .then(positions => {
        if (positions) {
          let _telemetry = positions.map(p => {
            return new Telemetry(p);
          });
          let qGetSpeedLimits = roadSpeeds
            ? googleService.getSpeedLimits.bind(googleService)
            : Promise.resolve;
          let qGetInterpolated = interpolate
            ? googleService.getSpeedLimits.bind(googleService)
            : Promise.resolve;

          return qGetSpeedLimits(_telemetry)
            .then(qGetInterpolated)
            .then(_tInterpolated => {
              this.telemetryData[id] = _tInterpolated;
              return this.telemetryData[id];
            });
        } else {
          return Promise.resolve(this.telemetryData[id]);
        }
      });
  }

  /**
   * Returns a device latlong list by id
   *
   * @param {(number|string)} id - the device id
   * @return {Array.<Number, Number>} array of lat long
   */
  getDevicePositionsById(id) {
    this.getByDeviceId(id).then(positions => {
      return (positions || []).map(p => {
        return p.latlng;
      });
    });
  }

  getJourneys(assetId, dtfrom, dtto, smallJourneys = false) {
    return this.api
      .get('api/telemetry/journeys', { ...this.api.getFilters(assetId, dtfrom, dtto), smallJourneys })
      .then(trips => {
        return (trips || []).map(t => {
          return new Journey(t);
        });
      });
  }

  markTripPersonal(id, isPersonal) {
    const url = this.api.buildUrl('api/telemetry/journeys', id);
    const update = { label: isPersonal ? JourneyLabels.personal : '' };

    return this.api.put(url, update).then(journey => {
      return new Journey(journey);
    });
  }
}

const telemetryService = new TelemetryService();

export default telemetryService;
