/** @format */

import { computedFrom } from 'aurelia-framework';
import apiService from 'services/api/apiService';
import { DateTimeUtils } from '@fonix/web-utils';

import { ReportTypes } from 'services/api/reportsService';

export const SchedulePeriods = {
  // once: 'once',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly'
};

export class ReportSchedule {
  constructor(data) {
    //defaults
    this.periodicity = SchedulePeriods.daily;
    this.settings = {
      reportId: 1 //journeys
    };
    Object.assign(this, data);
  }

  @computedFrom('reportName', 'reportType')
  get displayName() {
    return this.reportName || this.reportType;
  }

  @computedFrom('settings.reportId')
  get reportType() {
    return ReportTypes[this.reportId || this.settings.reportId];
  }

  @computedFrom('periodicity', 'settings.dates.To')
  get firstExecutionDate() {
    if (!this.id) {
      this.nextExecution = this.calcNextExecution(
        this.periodicity,
        this.settings.dates
      );
    }
    return this.nextExecutionLocal;
  }

  @computedFrom('nextExecution')
  get nextExecutionLocal() {
    if (this.nextExecution) {
      if (!this.lastExecuted || this.nextExecution >= this.lastExecuted)
        return DateTimeUtils.toLocal(this.nextExecution);
    }
  }

  @computedFrom('lastExecuted')
  get lastExecutedLocal() {
    if (this.lastExecuted) {
      return DateTimeUtils.toLocal(this.lastExecuted);
    }
  }

  calcNextExecution(period, reportDates = {}) {
    let now = new Date();
    switch (period) {
      case SchedulePeriods.daily:
        return DateTimeUtils.add(DateTimeUtils.startOf(now, 'day'), 1, 'day');
      case SchedulePeriods.weekly:
        return DateTimeUtils.add(DateTimeUtils.startOf(now, 'week'), 1, 'week');
      case SchedulePeriods.monthly:
        return DateTimeUtils.add(
          DateTimeUtils.startOf(now, 'month'),
          1,
          'month'
        );
      case SchedulePeriods.once:
        return reportDates.To;
      // return DateTimeUtils.add(now, 5, 'minutes');
    }
  }
}

export class ReportsSchedulesService {
  constructor() {
    this.api = apiService;
  }

  get(id) {
    let url = this.api.buildUrl('api/reports/schedules', id);
    return this.api.get(url).then(schedules => {
      let scheds = this.api.toArray(schedules).map(sc => {
        return new ReportSchedule(sc);
      });
      return id ? scheds[0] : scheds;
    });
  }

  update(schedule) {
    let url = this.api.buildUrl('api/reports/schedules', schedule.id);

    //clear lastexecuted from body as the api uses it to override stuff.
    // let sanitized = { ...schedule };
    // sanitized.lastExecuted = undefined;

    schedule.lastExecuted = undefined;
    return this.api.update(url, schedule).then(_updated => {
      return new ReportSchedule(Object.assign({}, schedule, _updated));
    });
  }

  delete(schedule) {
    let url = this.api.buildUrl('api/reports/schedules', schedule.id);
    return this.api.delete(url).then(() => true);
  }
}


const reportsSchedulesService = new ReportsSchedulesService;
export default reportsSchedulesService;
