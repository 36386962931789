/** @format */

import apiService from 'services/api/apiService';
import userService from 'services/api/userService';

//
export class Translation {
  constructor(data) {
    Object.assign(this, data);
  }
}

/**
 * The Service responsible for DRIVER resources
 */
export class TranslationsService {
  constructor() {
    this.api = apiService;
    this.userService = userService;
    this.translations = {};
  }

  getTranslations(culture) {
    let cultureID = culture || this.userService.getPreference('language');
    let url = this.api.buildUrl('api/localization/translations');
      return this.api.get(url, {'culture' : cultureID, format: 'json'}).then(_translations => {
      let translations = this.api.toArray(_translations).map(d => {
        return new Translation(d);
      });
      return translations;
    });
  }

  getTranslation(culture, id) {
    let cultureID = culture || this.userService.getPreference('language');
    let url = this.api.buildUrl('api/localization/translations');
    return this.api.get(url, {id: id}).then(_translations => {
      let translations = this.api.toArray(_translations).map(d => {
        return new Translation(d);
      });
      return translations.filter(translation => translation.cultureId == culture);
    });
  }

  create(translation) {
    let url = this.api.buildUrl('api/localization/translations');
    return this.api.post(url, translation).then(_updated => {
      return new Translation(Object.assign({}, translation, _updated));
    });
  }

  update(translation) {
    let url = this.api.buildUrl('api/localization/translations', translation.cultureId, translation.id);
    return this.api.update(url, translation).then(_updated => {
      return new Translation(Object.assign({}, translation, _updated));
    });
  }

  delete(translation) {
    let url = this.api.buildUrl('api/localization/translations', translation.id);
    return this.api.delete(url).then(() => {
      return true;
    });
  }
}

const translationsService = new TranslationsService();
export default translationsService;
