/** @format */

import { computedFrom } from 'aurelia-framework';
import apiService from 'services/api/apiService';
import { DateTimeUtils } from '@fonix/web-utils';

export class DeviceDiagnostic {
  constructor(data) {
    Object.assign(this, data);
  }

  @computedFrom('isEnabled', 'isCommunicating', 'isDelayed')
  get icon() {
    return !this.isEnabled || !this.isCommunicating || this.isDelayed
      ? 'fi-alert-circle'
      : 'fi-check-circle';
  }

  @computedFrom('manufacturerName', 'modelName')
  get fullModel() {
    return `${this.manufacturerName} ${this.modelName}`;
  }

  @computedFrom('updated')
  get updatedLocal() {
    if (this.updated) {
      return DateTimeUtils.toLocal(this.updated);
    }
  }

  @computedFrom('updatedLocal')
  get updatedCalendar() {
    if (this.updatedLocal) {
      return DateTimeUtils.toCalendar(this.updatedLocal);
    }
  }

  @computedFrom('updateDelay')
  get delayDuration() {
    return DateTimeUtils.durationtime(this.updateDelay);
  }

  @computedFrom('isEnabled', 'isCommunicating', 'isDelayed')
  get cssClass() {
    if (!this.isEnabled) {
      return 'inactive';
    }
    if (!this.isCommunicating) {
      return 'nocomm';
    }
    if (this.isDelayed) {
      return 'delayed';
    }
  }

  @computedFrom('isEnabled', 'isCommunicating', 'isDelayed')
  get statusText() {
    if (!this.isEnabled) {
      return 'disabled';
    }
    if (!this.isCommunicating) {
      return 'not communicating';
    }
    if (this.isDelayed) {
      return 'delayed';
    }
    return 'OK';
  }
}

/**
 * The Service responsible for Diagnostics resources
 */
export class DiagnosticsService {
  constructor() {
    this.api = apiService;
  }

  getSummary() {
    return this.api.get('api/telemetry/diagnostics').then(summary => {
      return summary;
    });
  }

  getDelayedDevices(usePaging = true) {
    return this.api
      .get('api/telemetry/diagnostics/devices/delayed', { usePaging })
      .then(this.pagedResponse.bind(this));
  }

  getNotCommunicating(usePaging = true) {
    return this.api
      .get('api/telemetry/diagnostics/devices/notCommunicating', { usePaging })
      .then(this.pagedResponse.bind(this));
  }

  getAssigned(usePaging = true) {
    return this.api
      .get('api/telemetry/diagnostics/devices/assigned', { usePaging })
      .then(this.pagedResponse.bind(this));
  }

  getDevices(filter, pageNumber, usePaging = true) {
    let url = this.api.buildUrl('api/telemetry/diagnostics/devices');
    return this.api.get(url, { ...filter, pageNumber, usePaging }).then(res => {
      res = res || {};
      return usePaging
        ? this.pagedResponse(res)
        : this.api.toArray(res).map(s => new DeviceDiagnostic(s));
    });
  }

  searchDevices(filter, pageNumber, usePaging = true) {
    let url = this.api.buildUrl('api/telemetry/diagnostics/devices/search');
    return this.api.get(url, { ...filter, pageNumber, usePaging }).then(res => {
      res = res || {};
      return usePaging
        ? this.pagedResponse(res)
        : this.api.toArray(res).map(s => new DeviceDiagnostic(s));
    });
  }

  getDevice(id) {
    return this.api
      .get(this.api.buildUrl('api/telemetry/devices', id))
      .then(device => {
        return new DeviceDiagnostic(device);
      });
  }

  assignAction(id, action) {
    return this.api
      .post(this.api.buildUrl('api/telemetry/diagnostics/devices', id), {action})
      .then(device => {
        return new DeviceDiagnostic(device);
      });
  }

  pagedResponse(res) {
    res = res || {};
    return {
      devices: this.api.toArray(res.items).map(s => new DeviceDiagnostic(s)),
      page: res.pageNumber,
      pageCount: res.pageCount
    };
  }
}

const diagnosticsService = new DiagnosticsService();
export default diagnosticsService;
