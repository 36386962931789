/** @format */

import {
  computedFrom
} from 'aurelia-framework';
import userService from 'services/api/userService';
import apiService from 'services/api/apiService';

export const DVRIds = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
];

export const IOSources = [
  'analog1',
  'analog2',
  'analog3',
  'analog4',
  'analog5',
  'analog6',

  'digital1',
  'digital2',
  'digital3',
  'digital4',
  'digital5',
  'digital6',
];

export const IOSourceTypes = ['generic', 'doorSensor', 'ignition', 'temperatureSensor', 'businessButton', 'panicButton'];

export class Device {
  constructor(data) {
    //defaults
    this.manufacturerId = -1;
    this.modelId = -1;

    Object.assign(this, data);
  }

  @computedFrom('isEnabled')
  get Enabled() {
    return this.isEnabled;
  }

  set Enabled(value) {
    this.isEnabled = value;
  }

  @computedFrom('manufacturerName', 'modelName')
  get fullModelName() {
    if (this.manufacturerName || this.modelName) {
      return `${this.manufacturerName || ''} ${this.modelName || ''}`;
    }
  }
}

export class ProvisionService {
  constructor() {
    this.api = apiService;

    this.userService = userService;

    this.entities = {
      resellers: this.getResellers.bind(this),
      accounts: this.getAccounts.bind(this),
      devices: this.getDevices.bind(this),
      manufacturer: this.getManufacturers.bind(this),
      model: this.getModels.bind(this)
    };

    //persist data across routes in /provision
    this.selectedAccountId = null;
    this.selectedResellerId = null;
  }

  getEntity(type, id, filter) {
    let typeFn = this.entities[type];
    if (typeFn) {
      return typeFn(id, filter);
    }
    return Promise.reject(type);
  }

  getResellers(id) {
    return this.userService.getResellers(id);
  }

  getAccounts(id, resellerId, showDisabled = false) {
    return this.userService.getAccounts(id, resellerId, showDisabled);
  }

  getDevices(id, accountId, showDisabled = true) {
    let url = `api/telemetry/devices${id ? `/${id}` : ''}`;
    return this.api
      .get(url, {
        accountId,
        showDisabled: id ? null : showDisabled
      })
      .then(devices => {
        return this.api.toArray(devices).map(d => {
          return new Device(d)
        });
      });
  }

  getManufacturers(id) {
    let url = this.api.buildUrl('api/telemetry/manufacturers', id);
    return this.api.get(url).then(items => {
      return this.api.toArray(items);
    });
  }

  getModels(id, manufacturerId) {
    let url = this.api.buildUrl('api/telemetry/models', id);
    return this.api
      .get(url, {
        manufacturerId
      })
      .then(items => {
        return id ? items : this.api.toArray(items);
      });
  }

  getFeatures() {
    return this.api.get('api/telemetry/features').then(features => {
      return features.map(f => {
        return {
          id: f.featureId,
          ...f
        };
      });
    });
  }

  updateReseller(reseller) {
    return this.userService.updateReseller(reseller);
  }

  setResellerLogo(id, file) {
    let url = this.api.buildUrl('api/resellers', id, 'logo');
    if (!file) {
      return this.api.delete(url);
    }
    const data = new FormData();
    data.set('file', file);
    return this.api.post(url, data);
  }

  updateAccount(account) {
    return this.userService.updateAccount(account);
  }

  updateDevice(device, enabled) {
    let url = this.api.buildUrl('api/telemetry/devices', device.id);

    device.Enabled = enabled; //send enabled flag in right property
    return this.api.update(url, device).then(item => {
      // let updated = Object.assign({}, device, item);
      return new Device(item);
    });
  }

  updateDevices(devices) {
    let url = this.api.buildUrl('api/telemetry/devices');

    return this.api.put(url, devices).then(item => {
      return new Device(item);
    });
  }

  transferDevice(deviceId, accountId, comment) {
    let url = this.api.buildUrl('api/telemetry/devices', deviceId, 'transfer');
    return this.api.post(url, {
      accountId,
      comment: comment ? {
        content: comment
      } : null
    });
  }

  transferDevices(devices, accountId, comment) {
    let url = this.api.buildUrl('api/telemetry/devices/transfer');
    return this.api.post(url, {
      ids: devices,
      accountId,
      comment: comment ? {
        content: comment
      } : null
    });
  }

  enableDevice(device) {
    let url = this.api.buildUrl('api/telemetry/devices', device, 'enable');
    return this.api.post(url, {});
  }

  disableDevice(device, archiveAsset) {
    let url = this.api.buildUrl('api/telemetry/devices', device, 'disable');
    return this.api.post(url, {
      archiveAsset
    });
  }

  enableDevices(devices) {
    let url = this.api.buildUrl('api/telemetry/devices/enable');
    return this.api.post(url, {
      ids: devices
    });
  }

  disableDevices(devices, archiveAsset) {
    let url = this.api.buildUrl('api/telemetry/devices/disable');
    return this.api.post(url, {
      ids: devices,
      archiveAsset
    });
  }

  archiveAsset(assetId) {
    let url = this.api.buildUrl('api/assets/archive');
    return this.api.post(url, {
      id: assetId
    }).then(() => true);
  }
}

const provisionService = new ProvisionService();
export default provisionService;
